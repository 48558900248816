import React, { useState, useEffect } from 'react';
import { Button, Container, FormControl, TextField, FormControlLabel, InputLabel, h4, Radio, RadioGroup, Slide, Autocomplete, Chip, Modal, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import CustomRadio from './RadioButton';
import { FileUploader } from './FileUploader';
import { MultipleFileUploader } from './MultipleFileUploader';
import { uploadAnswers } from '../utils/utils';
import WebViewComponent from './WebViewComponent';
import { generateProtocol } from '../utils/utils';
import { Grid } from 'react-loader-spinner';
import plus from '../icons/simple-line-icons_plus.svg';
import { uploadMultipleFilesUpdated, updatedSearchProtocols, uploadAndExtractMultipleFilesUpdated, readCosmosData} from '../utils/utils';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import table from '../staticFiles/sampleTable.json'
import { DataGrid } from '@mui/x-data-grid';




const ResearchComponent = ({randomId}) => {    
    const [showTableSet, setShowTableSet] = useState(true);
    const [slide, setSlide] = useState(0);
    const [protocolList, setProtocolList] = useState([]);
    const [protocolNameList, setProtocolNameList] = useState([]);
    const [protocolContentList, setProtocolContentList] = useState([]);
    const [protocolTags, setProtocolTags] = useState([]);
    const [protocolInputValue, setProtocolInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [protocolModal, setProtocolModal] = useState(false);

    const [items, setItems] = useState([{ name: 'study_name', description: 'What is the name of this clinical trial protocol?' }, { name: 'inclusion', description: 'What are the inclusion criteria for the study?' }, { name: 'exclusion', description: 'What are the exclusion criteria for the study?' }]);
    const [newItem, setNewItem] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [finishedUploading, setFinishedUploading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [transformedData, setTransformedData] = useState([]);


    

    useEffect(() => {
        const fetchData = async () => {
          if (finishedUploading) {
            try {
              const newData = await readCosmosData();
              setData(newData);
            } catch (error) {
              console.error('Error reading data from Cosmos:', error);
            }
          }
        };
      
        fetchData();
      }, [finishedUploading]);


    useEffect(() => {
        // Transform data for rows
        console.log('data here', data)
        const transformedData = data.map((item, index) => {
            const transformedItem = { id: index, 'file name': item.file_name };
        
            item.fields.forEach(field => {
            transformedItem[field.field_name] = field.answer;
            });
        
            return transformedItem;
        });
        
        // Generate columns
        const generateColumns = (data) => {
            if (!data || data.length === 0 || !data[0].fields || data[0].fields.length === 0) {
            return [];
            }
  
            const columns = data[0].fields.map((field) => ({
            field: field.field_name,
            headerName: field.field_name,
            width: 300,
            }));
    
            // Add file_name as a column
            columns.unshift({
            field: 'file name',
            headerName: 'File Name',
            width: 300,
            });
        
            return columns;
        };

        const columns = generateColumns(data);
        console.log('columns', columns)

        console.log('transformedData', transformedData)
        

        setTransformedData(transformedData);
        setColumns(columns);
    }, [data]);
    


    

    

    const handleAddItem = () => {
        if (newItem && newDescription) {
        setItems([...items, { name: newItem, description: newDescription }]);
        setNewItem('');
        setNewDescription('');
        }
    };

    const handleRemoveItem = (itemToRemove) => {
        setItems(items.filter(item => item !== itemToRemove));
    };


    const handleProtocolInputChange = (event) => {
        setProtocolInputValue(event.target.value);
      };
    
    const handleProtocolInputKeyDown = (event) => {
    if (event.key === 'Enter' && protocolInputValue) {
        setProtocolTags([...protocolTags, protocolInputValue]);
        setProtocolInputValue('');
        }
    };
    
    const handleProtocolDelete = (tagToDelete) => () => {
        setProtocolTags(protocolTags.filter((tag) => tag !== tagToDelete));
    };

   
    const handleContinue = (val) => {
        setSlide(slide + val);
    }

    const handleProtocolModalOpen = () => setProtocolModal(true);
    const handleProtocolModalClose = () => setProtocolModal(false);

    const handleProtocolFiles = (files, fileContents) => {
        try {
          // Handle saving the file content or any other logic as needed for each file
          const newFiles = [];
          console.log('files here', files)
    
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const content = fileContents[i];
            newFiles.push({ name: file.name, content });
            console.log('newFiles', newFiles)
          }
    
          // Update React state with the array of file objects
          setProtocolList(newFiles);
          setProtocolNameList(newFiles.map((file) => file.name));
          setProtocolContentList(fileContents);
          handleProtocolModalClose();
        } catch (e) {
          console.log('Error in handling files', e);
          setError(true);
        }
      };

    return (
        <div>
            <div className='main-panel'>
                <Slide direction="up" in={slide === 0} mountOnEnter unmountOnExit>
                    <div>
                        {showTableSet && <div style={{marginLeft:'20px'}}>
                            <div>
                                <p className='button-text-initial'>Your table sets:</p>
                            </div>
                        </div>}
                        <div className='initial-upload-container'>
                        {showTableSet && <div style={{marginLeft:'20px',  display: 'flex', justifyContent: 'center'}} className='initial-upload-button'>
                            <button key='upload-button' onClick={()=> handleContinue(1)}className="upload-initial" type="submit">
                            <div style={{ marginTop: 20, width: '100%', borderRadius: 10, backgroundColor: '#1DC989', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <img height={40} width={40} style={{ marginRight: '20px', fill: 'white' }} src={plus}></img>
                                <p className='button-text-initial' style={{ color: 'white', fontSize: 20 }}>Create new table </p>
                            </div>
                            </button>
                        </div>}
                        </div>
                    </div>
                </Slide>
                <Slide direction="up" in={slide === 1} mountOnEnter unmountOnExit>
                <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'80vw' }}>
                  <div className='literature-search'>
                    <div className='source-select'>
                      <p className='button-text-initial'>Upload previous protocols:</p>
                      <div className='source-button-container'>

                        {protocolList.length === 0 ?
                          <>
                            <button key='source-button' onClick={() => handleProtocolModalOpen()} className="source-button" type="submit">
                              <p className='button-text-initial'>Upload Files</p>
                            </button>
                          </> :
                          <>
                            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                            {protocolList.map((file) =>
                              <div className='multiple-file-upload'>
                                <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                              </div>
                            )}
                          </>}
                      </div>
                      <div className='literature-search'>
                        <p style={{ textAlign: 'center' }} className='button-text-initial'>Or search the web</p>
                        <p className='button-text-initial'>Published protocol keywords:</p>


                        <Autocomplete
                          multiple
                          value={protocolTags}
                          onChange={(event, newLitTags) => {
                            setProtocolTags(newLitTags);
                          }}
                          freeSolo
                          options={[]}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              value={protocolInputValue}
                              onChange={handleProtocolInputChange}
                              onKeyDown={handleProtocolInputKeyDown}
                              style={{ minWidth: '600px' }}
                            />
                          )}
                        />
                      </div>
                      <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                        handleContinue(1)

                      }}>
                        <p style={{fontSize:'20px'}} className='button-text-white'>Next</p>
                      </div>
                    </div>
                  </div>
                </div>
                </Slide>
                <Slide direction="up" in={slide === 2} mountOnEnter unmountOnExit>
                <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'80vw' }}>
                    <div>
                    <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Select the sections you would like to include in your table:</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell align='center'>Field Name</TableCell>
                                <TableCell align='center'>Question</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.name}>
                                <TableCell align='center' >{item.name}</TableCell>
                                <TableCell align='center'>{item.description}</TableCell>
                                <TableCell align='center'>
                                <IconButton onClick={() => handleRemoveItem(item)}>
                                    <DeleteIcon />
                                </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <TextField
                            value={newItem}
                            onChange={(event) => setNewItem(event.target.value)}
                            placeholder="Add new field"
                        />
                        <TextField
                            value={newDescription}
                            onChange={(event) => setNewDescription(event.target.value)}
                            placeholder="Add new question"
                        />
                        <Button onClick={handleAddItem}>Add</Button>
                    </div>
                    </div>
                    <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                        setLoading(true)
                        if (protocolList.length > 0) {
                            
                            await uploadAndExtractMultipleFilesUpdated(randomId, setLoading, protocolList, protocolContentList, setError, 'research', items, setFinishedUploading);
                            
                          }
                        if (protocolTags.length > 0) {
                            await updatedSearchProtocols(setLoading, protocolTags, setError, 'research', items, setFinishedUploading);
                        }
                          setFinishedUploading(true)
                          handleContinue(1)
                    }}>
                        <p style={{fontSize:'20px'}} className='button-text-white'>Generate Analysis</p>
                      </div>
                  
                    </div>
                </div>
                </Slide>
                <Slide direction="up" in={slide === 3} mountOnEnter unmountOnExit>
                <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width:'80vw' }}>
                    <div>
                    <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Your analysis has been generated:</h2>
                    {data && data.length >= 1 && <DataGrid rows={transformedData} columns={columns} pageSize={5} />}
                    </div>
                </div>
                </Slide>
                <Modal
                    open={protocolModal}
                    onClose={handleProtocolModalClose}>
                    <div className='updated-modal-container'>
                    <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload previous protocol data</h2>
                    <MultipleFileUploader handleFiles={handleProtocolFiles} />
                    </div>
                </Modal>
                {loading && <div className='loading-spinner-middle' style={loading ? { visibility: 'visible', display:'flex', flexDirection:'column', alignItems:'center', margin:'auto' } : { visibility: 'hidden' }}>
                    <Grid
                    height="80"
                    width="80"
                    color="#1DC989"
                    secondaryColor="#"
                    // wrapperClass="loading-spinner-right"
                    visible={true}
                    ></Grid>
                    <p className='green-text'>Loading...</p>
                </div>}
            </div>
        </div>
    )
}

export default ResearchComponent;