import React from 'react'
import { useState, useEffect, useRef, useReducer } from 'react';
import '../App.css';
import { Oval } from  'react-loader-spinner'


function SearchColumn({searchRef,searchResults,handleSearch,rightBarLoading}) {
    return(

        <div className='right-panel'>  
          <h2>Search Documents</h2>
          <div className='search-box'>
            <div>
            <form className='search-form' onSubmit={(e) => handleSearch(e)}>
              <input
                  className='search-input'
                  type="text"
                  placeholder="Search here"
                  ref={searchRef}/>
                <button type="submit">Submit</button>
                <div className='loading-spinner-right' style={rightBarLoading ? {visibility:'visible'} : {visibility:'hidden'}}>
                  <Oval
                  height={80}
                  width={80}
                  color="#000000"
                  secondaryColor="#"
                  wrapperClass="loading-spinner-right"
                  visible={true}
                  ></Oval>
                </div>
            </form>
            </div>
          </div>
          <div className='search-results'>
            {searchResults && searchResults.map((data) =>  {
              return(
                <div key={data.page_content}>
                  <h4 className='results-data'>{data.metadata.source.slice(5)}</h4>
                  <p className='results-content'>{data.page_content}</p>
                  {/* <button onClick={checkFileExtension(data.metadata.source.slice(5))}>Go to Link</button> */}
                </div>
              )
            }
            )}
          </div>
        </div>

    )

}

export default SearchColumn;

