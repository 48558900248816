import React from 'react';

const DocumentBoxInternal = ({ doc, makeActive, index, openInputFileModal }) => {
  return (
    <div className='document-box-internal' onClick={() => makeActive(doc.name, index)}>
      <h4>{doc.name}</h4>
      <hr className='separator'/>
      <div className='document-info'>
        <div className='document-dates'>
          <p className='document-date'>Created:</p>
        </div>
        <div className='document-dates' style={{marginLeft:10}}>
          <p className='document-date'>{doc.creation_time}</p>
        </div>
      </div>
      <div className='document-sources'>
        <p className='document-date'>Sources:</p>
        <div className='sources-box'>
          {doc.sources.slice(0,3).map((source, index) => (
            <div onClick={()=> openInputFileModal(1, doc.input_name)}>
              <p key={index} className='source'>{source.source_data[0]?.metadata.source}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentBoxInternal;