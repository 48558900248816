import React, { useState, useEffect } from 'react';
import close from '../icons/Close_round.svg'
import sections from './sections.json'
import { findInconsistencies } from '../utils/utils';
import {Oval} from 'react-loader-spinner';



function InconsistencyComponent({handleClose, data, openInputModal, inputDoc }) {
    const [selectedSource, setSelectedSource] = useState(''); // State for the selected source
    const [metadata, setMetadata] = useState([]);
    const sourceOptions = sections
    const [inconsistencyLoading, setInconsistencyLoading] = useState(false)


  
    // useEffect(() => {
    //   if (selectedSource) {
    //     const result = findSourceDataWithSection(data, selectedSource);
    //     setMetadata(result);
    //   }
    // }, [selectedSource, data]);
  
    // function findSourceDataWithSection(data, section) {
    //     const result = [];
      
    //     data.forEach(item => {
    //       if (item.heading_name === section) {
    //         result.push(...item.source_data);
    //       }
    //     });
      
    //     return result;
    // }

    useEffect(() => {
        const fetchData = async () => {
          if (selectedSource) {
            setInconsistencyLoading(true);
            const result = await findInconsistencies('cb4dbc01-27eb-4717-baf9-c25cbb940524-hoffmansynopsisfullgenpdfprotocoltemplatedocx.docx', selectedSource, setInconsistencyLoading );
            console.log('result here', result)
            setMetadata(result);
            setInconsistencyLoading(false);
          }
        };
      
        fetchData();
      }, [selectedSource, data]);
    
  
  // Usage:
  



  return (
    <div className='right-panel-content'>
        <div className='right-panel-header'>
            <h2 className='blue-text'>Check Consistency</h2>
            {/* <img className='close-button' src={close} onClick={() => handleClose()}></img> */}
        </div>
      <div className='regenerate-box'>
        <div>
          <h4 className='blue-text'>Section:</h4>
          <select className='select-input' value={selectedSource} onChange={(e) => setSelectedSource(e.target.value)}>
            <option value="">Select a section</option>
            {sourceOptions.map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
          </select>
        </div>
        <div>
          {metadata && metadata.length > 0 && <h4 className='blue-text'>Inconsistent Sections:</h4>}
          <div className='source-box-container'>
            {metadata.slice(0, 5).map((item, index) => (
                <div style={{cursor:'pointer'}} className='sources-panel-box'>
                    <p className='source'>{`hoffmansynopsis-protocol.pdf`}</p>
                    <div>
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
            {inconsistencyLoading && <div className='loading-spinner-middle' style={inconsistencyLoading ? { visibility: 'visible', display:'flex', justifyContent:'center', marginLeft:'100px', alignContent:'center', flexDirection:'column'} : { visibility: 'hidden' }}>
                      <Oval
                        height="80"
                        width="100"
                        color="#000000"
                        secondaryColor="#"
                        // wrapperClass="loading-spinner-right"
                        visible={true}
                      ></Oval>
                      <p style={{textAlign:'center'}} className='blue-text'>Loading...</p>
                    </div>}
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default InconsistencyComponent;