import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { BlobServiceClient } from "@azure/storage-blob";
import { DefaultAzureCredential, InteractiveBrowserCredential } from "@azure/identity";
import existingFiles from '../staticFiles/existingFiles.json'
import sourceData from '../staticFiles/sourceData.json'
// import crypto from 'crypto';


function delayExecution(callback, delay) {
  setTimeout(callback, delay);
}

const delay = ms => new Promise(res => setTimeout(res, ms));



const msalConfig = {
    auth: {
        clientId: '70f0ff96-0505-4270-92df-76babba0f3d7'
    }
};

const msalInstance = new PublicClientApplication(msalConfig);


var request = {
  scopes: ["api://10db4c25-3f20-4719-b421-bb23cce19cd7/.default"]
};

let tokenResponse;
let objectId

const signInUser = async () => {
  await msalInstance.initialize();
  console.log('signing in',msalInstance)
  try {
    tokenResponse = await msalInstance.acquireTokenSilent(request);
    if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
      throw new Error('Invalid access token');
    }
    objectId = tokenResponse.account.idTokenClaims.oid;
    console.log('User objectId:', objectId);
  } catch (error) {
    console.log('error not in interaction',error)
    if (error) {
      console.error('error in sign in', error)
      // fallback to interaction when silent call fails
      tokenResponse = await msalInstance.acquireTokenPopup(request);
      if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
        throw new Error('Invalid access token');
      }
      objectId = tokenResponse.account.idTokenClaims.oid;
      console.log('User objectId: 2', objectId);
    }
    // handle other errors
  }
};



export const testAuth = async() =>  {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  var headers = new Headers();
  headers.append("Authorization", "Bearer " + tokenResponse.accessToken);

  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: headers
  };
  
  console.log('testing auth')
  
  fetch("https://artos-authorized-functions.azurewebsites.net/api/secondauthtest", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

}


export const viewExistingFiles = async() =>  {

  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }


  var headers = new Headers();
  headers.append("Authorization", "Bearer " + tokenResponse.accessToken);

  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: headers
  };
  
  console.log('testing auth')
  
  try {
    // const response = await fetch("https://artos-authorized-functions.azurewebsites.net/api/getexistingfiles", requestOptions);
    // const result = await response.json();
    delayExecution(() => {
      console.log('This will be logged after the delay');
    }, 2000);
    const result = existingFiles;
    console.log('existingfiles', result);
    return result;
  } catch (error) {
    console.log('error', error);
    throw error; // re-throw the error, if you want to handle it in the calling code
  }

}


export const uploadFile = async(setLoading,fileName,file,fileObj,setUploadedFileUrl,setError) =>  {
  setLoading(true)
  console.log('file here',file)
  const formData = new FormData();
  formData.append('file_name', fileName);
  formData.append('file_content', new Blob([file]));

  try {
    // Make an HTTP request to the Azure Function with the file content
    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    const response = await fetch(`https://artosfunctions.azurewebsites.net/api/uploadfile?file_name=${fileName}`, requestOptions);

    if (!response.ok) {
      throw new Error(`Error uploading file: ${response.statusText}`);
    }

    const result = await response.text();
    setUploadedFileUrl(window.URL.createObjectURL(fileObj))
    setLoading(false)

    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading file:', error);
    setError(true);
    setLoading(false)
  }
}


export const uploadMultipleFiles = async (setLoading, files, filesContent, setError) => {

  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  setLoading(true);


  console.log('filesContent', filesContent)

  try {
    // Use Promise.all to upload multiple files concurrently
    const uploadPromises = files.map(async (file,i) => {
      console.log('file here', filesContent[i])
      const formData = new FormData();
      formData.append('file_name', file.name);
      formData.append('file_content', new Blob([filesContent[i]]));

      var headers = new Headers();
      headers.append("Authorization", "Bearer " + tokenResponse.accessToken);

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: headers,
      };

      const response = await fetch(`https://artos-authorized-functions.azurewebsites.net/api/uploadfile`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
      }

      const result = await response.text();
      
      console.log('file name:', result)
    });

    // await Promise.all(uploadPromises);
    await delay(2000);

    setLoading(false);

    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};

export const saveFile = async ( fileName, fileContent) => {

  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }



  console.log('filesContent', fileContent)

  try {
    // Use Promise.all to upload multiple files concurrently
    
      console.log('file here', fileName)
      const formData = new FormData();
      formData.append('file_name', fileName);
      formData.append('file_content', fileContent);

      var headers = new Headers();
      headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
      headers.append("X-MS-CLIENT-PRINCIPAL-ID", '050e6292-662f-4b0f-9a89-4b0beca8bed6')

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: headers,
      };

      const response = await fetch(`https://artos-authorized-functions.azurewebsites.net/api/saveFile`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error uploading file ${fileName}: ${response.statusText}`);
      }

      const result = await response.text();
      console.log('file name:', result)

    


    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading files:', error);

  }
};

export const uploadMultipleFilesUpdated = async (setLoading, files, filesContent, setError, container) => {

  if (!tokenResponse || !objectId) {
  console.log('not signed in')
  try {
  await signInUser();
  } catch (error) {
  console.log('error in authentication', error)
  }
    
  }

  setLoading(true);


  console.log('filesContent', filesContent)

  try {
    // Use Promise.all to upload multiple files concurrently
    const uploadPromises = files.map(async (file,i) => {
      console.log('file here', filesContent[i])
      const formData = new FormData();
      formData.append('file_name', file.name);
      formData.append('file_content', new Blob([filesContent[i]]));

      var headers = new Headers();
      headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
      headers.append('container', container)

      // headers.append('X-MS-CLIENT-PRINCIPAL-ID', '050e6292-662f-4b0f-9a89-4b0beca8bed6')

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: headers,
      };

      const response = await fetch(`https://artos-authorized-functions.azurewebsites.net/api/uploadFileUpdated`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
      }

      const result = await response.text();
      
      console.log('file name:', result)
    });

    // await Promise.all(uploadPromises);
    await delay(2000);

    setLoading(false);

    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};

export const newUploadMultipleFiles = async (randomId, files, filesContent, container) => {





  console.log('filesContent', filesContent)

  try {
    // Use Promise.all to upload multiple files concurrently
    const uploadPromises = files.map(async (file,i) => {
      console.log('file here', filesContent[i])
      console.log('file', file.name)
      const formData = new FormData();
      formData.append('file_name', file.name);
      formData.append('file_content', new Blob([filesContent[i]]));
      formData.append('randomId', randomId); // Add randomId to the request body



      var headers = new Headers();
      // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
      headers.append('container', container)

      // headers.append('X-MS-CLIENT-PRINCIPAL-ID', '050e6292-662f-4b0f-9a89-4b0beca8bed6')

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: headers,
      };

      const response = await fetch(`https://demoplaygroundfunctions.azurewebsites.net/api/uploadfileupdated`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
      }

      const result = await response.text();
      
      console.log('file name:', result)
    });

    await Promise.all(uploadPromises);
    // await delay(2000);


    // Handle the response from the Azure Function if needed
  } catch (error) {
    console.error('Error uploading files:', error);
  }
};

export const writeToDatabase = async (randomId, internalInfo, externalInfo, document, email, setError) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  const raw = JSON.stringify({
    "randomId": randomId,
    "internal_info": internalInfo,
    "external_info": externalInfo,
    "email": email,
    "document": document
  });
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  fetch("https://demoplaygroundfunctions.azurewebsites.net/api/writetodatabase", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));


}

export const findInconsistencies = async (file, section, setLoading) => {

  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  setLoading(true)

  var myHeaders = new Headers();
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");
  // myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
  
  var raw = JSON.stringify({
    "input_path": [file],
    "pc_index_name": "outputdata",
    "input_container": "output",
    "doc_type": "",
    "section_name": section
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  // await fetch("https://artos-authorized-functions.azurewebsites.net/api/findInconsistencies", requestOptions)
  //   .then(response => response.json())
  //   .then(result => result)
  //   .catch(error => console.log('error', error));
  const result = await fetch("https://artos-authorized-functions.azurewebsites.net/api/findInconsistencies", requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));

  setLoading(false)

  return result;
}

export const searchLiterature = async (setLoading, literatureTags, setError) => {

  console.log('searching literature')

  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  setLoading(true)


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6")

  var raw = JSON.stringify({
    "query": literatureTags.join(' ')
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch("https://artos-authorized-functions.azurewebsites.net/api/searchExistingLiterature", requestOptions)
    .then(response => response.text())
    .then(result => console.log('search literature result', result))
    .catch(error => {
      console.log('error', error);
      setError(true);
      setLoading(false);

    });


  setLoading(false)
  console.log('success in uploading literature')

  }



  export const searchProtocols = async (setLoading, protocolTags, setError) => {

    console.log('searching protocols')
  
    if (!tokenResponse || !objectId) {
      console.log('not signed in')
      try {
        await signInUser();
      } catch (error) {
        console.log('error in authentication', error)
      }
      
    }
  
    setLoading(true)
  
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
    // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6")
  
    var raw = JSON.stringify({
      "conditions": protocolTags
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    await fetch("https://artos-authorized-functions.azurewebsites.net/api/searchPreviousProtocols", requestOptions)
      .then(response => response.text())
      .then(result => console.log('search literature result', result))
      .catch(error => {
        console.log('error', error);
        setError(true);
        setLoading(false);
  
      });
  
  
    setLoading(false)
    console.log('success in uploading literature')
  
    }


    export const updatedSearchProtocols = async (setLoading, protocolTags, setError, container, fields, setFinishedUploading) => {

      console.log('searching protocols')
    
      if (!tokenResponse || !objectId) {
        console.log('not signed in')
        try {
          await signInUser();
        } catch (error) {
          console.log('error in authentication', error)
        }
        
      }
    
      setLoading(true)
    
    
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
      myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6")
  

      const raw = JSON.stringify({
        "conditions": protocolTags,
        "fields": fields,
        "input_container": "research",
        "pc_index_name": "externaldata",
        "doc_type": "protocol"
      });
    
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
    
      await fetch("http://localhost:7071/api/updatedSearchPreviousProtocols", requestOptions)
        .then(response => response.text())
        .then(result => console.log('search literature result', result))
        .catch(error => {
          console.log('error', error);
          setError(true);
          setLoading(false);
    
        });
    
    
      setLoading(false)
      console.log('success in uploading literature')
    
      }
  




export const uploadAnswers = async (setLoading, body, setError) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  

  console.log('uploading answers')
  setLoading(true)

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);


  var raw = JSON.stringify(body);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  // fetch("https://artos-authorized-functions.azurewebsites.net/api/uploadanswers", requestOptions)
  //   .then(response => response.text())
  //   .then(result => {console.log(result)})
  //   .catch(error => console.log('error', error));
  //   // Handle the response from the Azure Function if needed
  await delay(1000);

  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};


export const getQuestions = async (setLoading, file, setError, setQuestionList) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }

  setLoading(true);
  console.log('getting questions')

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);


    var sections = ["1. PROTOCOL SUMMARY"];

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };


    console.log('file in getQuestions',file)

    var requests = sections.map(section => {
      var raw = JSON.stringify({
        "input_path": [file],
        "input_container":"input",
        "quick_testing": "False",
      });

      requestOptions.body = raw;

      var raw2 = JSON.stringify({
        "input_path": file
      });

      requestOptions2.body = raw2;

      // First, call the api/createvectorstore endpoint
      return fetch("https://artos-authorized-functions.azurewebsites.net/api/createvectorstore", requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          // Don't return the result of this API call
        })
        .then(() => {
          // Once the first request is completed, call the api/getquestions endpoint
          return fetch("https://artos-authorized-functions.azurewebsites.net/api/getquestions", requestOptions2)
            .then(response => {
              if (!response.ok) {
                throw new Error(response.status);
              }
              return response.json();  // This result will be returned by the Promise
            });
        })
        .catch(error => console.log('error', error));
    });

    const overallQuestions = [];

    await delay(2000);


    setQuestionList(overallQuestions);
    setLoading(false)

    // await Promise.all(requests)
    //   .then(results => {
    //     console.log('results from getuestions', results)
    //     results.forEach(result => {
    //       if (result && result.missing_questions) {
    //         result.missing_questions.forEach(question => {
    //           overallQuestions.push(question.question); // Accumulating questions
    //         });
    //       }
    //     });
    //     setQuestionList(overallQuestions);
    //     setLoading(false)
    //   })
    //     .catch(error => {
    //       console.error('Error uploading files:', error);
    //       setError(true);
    //       setLoading(false);
    //     });
        // Handle the response from the Azure Function if needed
      } catch (error) {
        console.error('Error uploading files:', error);
        setError(true);
        setLoading(false);
      }
    };


export const generateProtocol = async (setLoading, file, setError, setProtocolFile, setSourceFile, setFileKey) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  console.log('generating protocol')

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);

    console.log('file in protocol',file)


    // var sections = ["1. PROTOCOL SUMMARY", "2. INTRODUCTION", "3. TRIAL OBJECTIVES, ENDPOINTS, AND ESTIMANDS", "4. TRIAL DESIGN", "5. TRIAL POPULATION", "6. TRIAL INTERVENTION AND CONCOMITANT THERAPY", "7. DISCONTINUATION OF TRIAL INTERVENTION AND PARTICIPANT WITHDRAWAL FROM TRIAL", "8. TRIAL ASSESSMENTS AND PROCEDURES", "10. GENERAL CONSIDERATIONS: REGULATORY, ETHICAL, AND TRIAL OVERSIGHT", "11. GENERAL CONSIDERATIONS: RISK MANAGEMENT AND QUALITY ASSURANCE"]
    var sections = ["1. PROTOCOL SUMMARY", "2. INTRODUCTION", "3. TRIAL OBJECTIVES, ENDPOINTS, AND ESTIMANDS", "4. TRIAL DESIGN", "5. TRIAL POPULATION"]
    // var sections = ["6. TRIAL INTERVENTION AND CONCOMITANT THERAPY", "7. DISCONTINUATION OF TRIAL INTERVENTION AND PARTICIPANT WITHDRAWAL FROM TRIAL", "8. TRIAL ASSESSMENTS AND PROCEDURES", "10. GENERAL CONSIDERATIONS: REGULATORY, ETHICAL, AND TRIAL OVERSIGHT", "11. GENERAL CONSIDERATIONS: RISK MANAGEMENT AND QUALITY ASSURANCE"]


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };


    let finalResponse;

    for (const section of sections) {
      var raw = JSON.stringify({
        "input_path": file,
        "section": section,
        "quick_testing": "False",
      });

      requestOptions.body = raw;
      

      // finalResponse = await fetch("https://artos-authorized-functions.azurewebsites.net/api/generateprotocol", requestOptions)
      //   .then(response => response.json())
      //   .catch(error => console.log('error', error));
      // }
    }

    await delay(5000);


    // console.log('final response', finalResponse)
    // const protocol_file = finalResponse['docx_file'].replace(/ /g, '+');
    // const source_file = JSON.parse(finalResponse['json_data']);

    const protocol_file = 'https://3f693757d182022c.blob.core.windows.net/output/hoffmansynopsisfullgenpdf-protocol_final.docx'
    const source_file = sourceData;

    // var requestOptions2 = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // var raw2 = JSON.stringify({
    //   "input_path": protocol_file,
    //   "headings": ["2.1.1 Background"]
    // });

    // requestOptions2.body = raw2;


    // await fetch("https://artos-authorized-functions.azurewebsites.net/api/saveProtocol", requestOptions2)
    //     .then(response => response.text())
    //     .then(result => console.log('saveProtocol',result))
    //     .catch(error => console.log('error', error));

    // const url = urlWithoutSpaces.slice(1, -1)
    setProtocolFile(protocol_file+'?cache_key='+Date.now());
    setSourceFile(source_file);
    setFileKey(Date.now())
  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};


export const updatedGenerateProtocol = async (setLoading, file, setError, setProtocolFile, setSourceFile, setFileKey, randomId) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
  }

  console.log('generating protocol')
  setLoading(true)





  var myHeaders = new Headers();
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
  myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");

  var raw = JSON.stringify({
    "input_path": file,
    "source_info": ["NCT00733408_Prot_SAP_000.pdf", "NCT02941926_Prot_000.pdf"],
    "section_name": "2.1.1 Background",
    "docx_template": "Prot_SAP_000.docx",
    "json_structure": "hoffmansynopsisfullgenpdfprotocoldocx-structure.json",
    "run": 0,
    "pc_index_name": "inputdata",
    "doc_type": "protocol",
    "input_container": "intermediate",
    "quick_testing": "False",
    "existing_file": false,
    "randomID": randomId,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch("https://artos-authorized-functions.azurewebsites.net/api/draftSection", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      setProtocolFile(result['docx_file']+'?cache_key='+Date.now());
      setLoading(false)
      
    }
    )
    .catch(error => {console.log('error', error)
    setLoading(false)});

}

export const extractAndStore = async(file, randomId, setLoading) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
  }

  setLoading(true)

  console.log('extracting and storing')

  var myHeaders = new Headers();
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6" );
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken); 

  var raw = JSON.stringify({
    "input_path": file,
    "pc_index_name": "inputdata",
    "input_container": "input",
    "doc_type":"",
    "randomID": randomId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch("https://artos-authorized-functions.azurewebsites.net/api/extractAndStore", requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log(result)
      setLoading(false)
    }
    )
    .catch(error => {console.log('error', error)
    setLoading(false)});
    

}

export const createOutputVectorStore = async(file, randomId, setLoading) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
  }

  console.log('generating output vector store')

  var myHeaders = new Headers();
  // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);

  var raw = JSON.stringify({
    "input_path": file,
    "pc_index_name": "outputdata",
    "input_container": "output",
    "doc_type":"",
    "randomID": randomId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch("https://artos-authorized-functions.azurewebsites.net/api/createOutputVectorStore", requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log(result)
      setLoading(false)
    }
    )
    .catch(error => {console.log('error', error)
    setLoading(false)});

}


export const saveProtocol = async (protocolName) =>{
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  console.log('saving protocol')

  var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);

  const fileName = protocolName.split("/").pop()


  var raw = JSON.stringify({
    "input_path": fileName,
    "headings": [ "2.1.1 Background"]
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  console.log('saved')

  // fetch("https://artos-authorized-functions.azurewebsites.net/api/saveprotocol", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log('saved protocol function', result))
  //   .catch(error => console.log('error', error));

}

export const regenerateSection = async (setLoading, protocolName, section,guidance, setError, setProtocolFile, setSourceFile, setFileKey, setHasLink) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  setProtocolFile("")
  setSourceFile("")
  console.log('regenerating protocol')
  setLoading(true)

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    let finalResponse;

    console.log('file here', protocolName)

    var raw = JSON.stringify({
      "input_path": protocolName,
      "heading": section,
      "guidance": guidance,
    });
  
    requestOptions.body = raw;
  
    // finalResponse = await fetch("https://artos-authorized-functions.azurewebsites.net/api/regenerateSection", requestOptions)
    //   .then(response => response.json())
    //   .catch(error => console.log('error', error));
    

    // console.log('final response', finalResponse)
    // const protocol_file = finalResponse['docx_file'].replace(/ /g, '+');
    // const source_file = finalResponse['json_data'];
    // console.log('source_file', source_file)

    const protocol_file = 'https://3f693757d182022c.blob.core.windows.net/output/hoffmansynopsisfullgenpdf-protocol_REVISED_V2.docx';
    const source_file = sourceData;




    // const url = urlWithoutSpaces.slice(1, -1)
    setProtocolFile(protocol_file+'?cache_key='+Date.now());
    setSourceFile(source_file);
    setFileKey(Date.now())
    await delay(5000);
    setHasLink(true)



    setLoading(false)
  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};

export const updatedRegenerateSection = async (setLoading,protocolName, section, guidance, setError) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  console.log('regenerating protocol')
  // setLoading(true)

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
    myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6")
    var fileName = protocolName.split(".docx")[0] + ".docx";
    var lastSlashIndex = fileName.lastIndexOf("/");
    var result = fileName.substring(lastSlashIndex + 1);

    console.log('result here',result)

    




    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    let finalResponse;

    console.log('file here', protocolName)

    var raw = JSON.stringify({
      "input_path": result ? result : "hoffmansynopsisfullgenpdf-protocol_final_02-08-24.docx",
      "section": section,
      "guidance": guidance,
    });
  
    requestOptions.body = raw;
  
    finalResponse = await fetch("https://artos-authorized-functions.azurewebsites.net/api/updatedRegenerateSection", requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error));
    

    console.log('final response', finalResponse)
    return finalResponse;
    // const protocol_file = finalResponse['docx_file'].replace(/ /g, '+');
    // const protocol_file = finalResponse;
    // const source_file = finalResponse['json_data'];
    // console.log('source_file', source_file)

    // const protocol_file = 'https://3f693757d182022c.blob.core.windows.net/output/hoffmansynopsisfullgenpdf-protocol_REVISED_V2.docx';
    // const source_file = sourceData;




    // const url = urlWithoutSpaces.slice(1, -1)
    // setProtocolFile(protocol_file+'?cache_key='+Date.now());
    // setSourceFile(source_file);
    // setFileKey(Date.now())
    // await delay(5000);
    // setHasLink(true)



    setLoading(false)
  } catch (error) {
    console.error('Error uploading files:', error);
    setError(true);
    setLoading(false);
  }
};


export const createVectorStore = async (protocolName, setLoading, setError) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  console.log('creating vectorstore')
  console.log('protocolName in vectorstore', protocolName)
  setLoading(true)

  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };


    console.log('file here', protocolName)

    var raw = JSON.stringify({
      "input_path": [protocolName],
      "input_container":"output",
      "quick_testing": "False",
    });
  
    requestOptions.body = raw;

    // fetch("https://artos-authorized-functions.azurewebsites.net/api/createvectorstore", requestOptions)
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error("Error creating vector store for protocol");
    //     }
    //   })
    // .catch(error => console.log('error', error));

    console.log('vectorstore successfully created')
    setLoading(false)
  } catch (error) {
    console.error('Error creating vectorstore', error);
    setError(true);
    setLoading(false);
  }
}


export const chatWithBot = async (question, protocolFile) => {
  if (!tokenResponse || !objectId) {
    console.log('not signed in')
    try {
      await signInUser();
    } catch (error) {
      console.log('error in authentication', error)
    }
    
  }
  console.log('chatting')
  const fileName = protocolFile.split("/").pop()
  console.log('fileNam in chatwithbot', fileName)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);


  var raw = JSON.stringify({
    // "input_path": protocolFile,
    "input_path": fileName,
    "section": "protocol",
    "subsection": "Introduction",
    "question": question
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  // return fetch("https://artos-authorized-functions.azurewebsites.net/api/chatwithdoc", requestOptions)
  //   .then(response => response.json())
  //   // .then(result => console.log(result))
  //   .catch(error => console.log('error', error));
  await delay(2000);



  }


  export const chatWithDoc = async (question) => {
    if (!tokenResponse || !objectId) {
      console.log('not signed in')
      try {
        await signInUser();
      } catch (error) {
        console.log('error in authentication', error)
      }
      
    }
    console.log('chatting')
    // const fileName = protocolFile.split("/").pop()
    // console.log('fileNam in chatwithbot', fileName)
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);
  
  
    var raw = JSON.stringify({
      // "input_path": protocolFile,
      "input_path": "hoffman",
      "section": "protocol",
      "subsection": "Introduction",
      "question": question
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    return fetch("https://artos-authorized-functions.azurewebsites.net/api/chatwithdoc", requestOptions)
      .then(response => response.text())
      // .then(result => console.log(result))
      .catch(error => console.log('error', error));
    // await delay(2000);
  
  
  
    }


  export const getSources = async (section) => {
    if (!tokenResponse || !objectId) {
      console.log('not signed in')
      try {
        await signInUser();
      } catch (error) {
        console.log('error in authentication', error)
      }
      
    }
    console.log('chatting')
    // const fileName = protocolFile.split("/").pop()
    // console.log('fileNam in chatwithbot', fileName)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);


    var raw = JSON.stringify({
      // "input_path": protocolFile,
      "input_path": "hoffman",
      "section": section,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    return fetch("https://artos-authorized-functions.azurewebsites.net/api/getsources", requestOptions)
      .then(response => response.json())
      // .then(result => console.log(result))
      .catch(error => console.log('error', error));
    // await delay(2000);



    }

export const handleSearch = (e, setRightBarLoading,searchRef,fileName,setError, setSearchResults) => {
    e.preventDefault();
    setRightBarLoading(true)
    console.log('searching...')
    console.log('search value', searchRef.current.value)
    const searchValue = searchRef.current.value

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "input_path": fileName,
      "query_text": searchValue
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://wzxp6ag4drkafz7mxxpu7rkwoe0eiufh.lambda-url.us-east-1.on.aws/", requestOptions)
      .then(response => {
        if (!response.ok) {
          setError(true)
          setRightBarLoading(false)
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json()})
      .then(async result => {
        console.log('result',result)
        setRightBarLoading(false)
        setSearchResults(result)

        })
      .catch(error => console.log('error', error));

    searchRef.current.value=''

    }


export const generateICF = async (event, file, setLoading, handleGenerateModalClose, setError, fileName, setICFResultFile, setActive) => {
    if (file) {

      setLoading(true)
      handleGenerateModalClose()
      setError(false)
      try {
        event.preventDefault();
        async function generate () {
          console.log('generating ICF from ', fileName)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "input_path": fileName
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://artosfunctions.azurewebsites.net/api/createicf", requestOptions)
            .then(response => {
              if (!response.ok) {
                setError(true)
                setLoading(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.text();
            }
              )
            .then(async result => {
              console.log('result here', result, typeof result)

              console.log('raw url ', result)
              const urlWithoutSpaces = result.replace(/ /g, '+');
              // const url = urlWithoutSpaces.slice(1, -1)
              setICFResultFile(urlWithoutSpaces)
              setActive(1)
              setLoading(false)
              
            }
            )
            .catch((error) => {
              console.log('error in api call', error)
              setLoading(false)
              setICFResultFile(null)
              setError(true)
              
            })

          }
          await generate();
      }
      catch (e) {
        console.log('error', e)
        setError(true)

      }
    }
  };


export  const generateIB = async (event, file, setLoading, handleGenerateModalClose, setError, fileName, setIBResultFile, setActive) => {
    if (file) {

      setLoading(true)
      handleGenerateModalClose()
      setError(false)
      try {
        event.preventDefault();
        async function generate () {
          console.log('generating IB from ', fileName)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "input_path": fileName,
            "section": "Summary",
            "guide_text": "",
            "subsection": "Overview"
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://46c7lanfweh22abcs2ozo6ommq0dcnbi.lambda-url.us-east-1.on.aws/", requestOptions)
            .then(response => {
              if (!response.ok) {
                setError(true)
                setLoading(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.text();
            }
              )
            .then(async result => {
              console.log('result here', result, typeof result)

              console.log('raw url ', result)
              const urlWithoutSpaces = result.replace(/ /g, '+');
              const url = urlWithoutSpaces.slice(1, -1)
              setIBResultFile(url)
              setActive(2)
              setLoading(false)
              
            }
            )
            .catch((error) => {
              console.log('error in api call', error)
              setLoading(false)
              setIBResultFile(null)
              setError(true)
            })

          }
          await generate();
      }
      catch (e) {
        console.log('error', e)
        setError(true)

      }
    }
  };

export const generateIB2 = async (event, file, setLoading, handleGenerateModalClose, setError, fileName, textRef, selectedSubcategory, setIBResultFile, setActive) => {
    if (file) {

      setLoading(true)
      handleGenerateModalClose()
      setError(false)
      try {
        event.preventDefault();
        async function generate () {
          console.log('generating IB from ', fileName)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "input_path": fileName,
            "section": "Summary",
            "guide_text": textRef.current.value,
            "subsection": selectedSubcategory,
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://46c7lanfweh22abcs2ozo6ommq0dcnbi.lambda-url.us-east-1.on.aws/", requestOptions)
            .then(response => {
              if (!response.ok) {
                setError(true)
                setLoading(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.text();
            }
              )
            .then(async result => {
              console.log('result here', result, typeof result)

              console.log('raw url ', result)
              const urlWithoutSpaces = result.replace(/ /g, '+');
              const url = urlWithoutSpaces.slice(1, -1)
              setIBResultFile(url)
              setActive(2)
              setLoading(false)
              
            }
            )
            .catch((error) => {
              console.log('error in api call', error)
              setLoading(false)
              setIBResultFile(null)
              setError(true)
            })

          }
          await generate();
      }
      catch (e) {
        console.log('error', e)
        setError(true)

      }
    }
  };


export const generateISIP = async (event, file, setLoading, handleGenerateModalClose, setError, fileName, setISIPFile, setActive ) => {
    if (file) {

      setLoading(true)
      handleGenerateModalClose()
      setError(false)
      try {
        event.preventDefault();
        async function generate () {
          console.log('generating ISIP from ', fileName)
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "input_path": fileName
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://dw6tblrc7xw7os5jk77egpoidi0owoqe.lambda-url.us-east-1.on.aws/ ", requestOptions)
            .then(response => {
              if (!response.ok) {
                setError(true)
                setLoading(false)
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.text();
            }
              )
            .then(async result => {
              console.log('result here', result, typeof result)

              console.log('raw url ', result)
              const urlWithoutSpaces = result.replace(/ /g, '+');
              const url = urlWithoutSpaces.slice(1, -1)
              setISIPFile(url)
              setActive(3)
              setLoading(false)
              
            }
            )
            .catch((error) => {
              console.log('error in api call', error)
              setLoading(false)
              setISIPFile(null)
              setError(true)
              
            })

          }
          await generate();
      }
      catch (e) {
        console.log('error', e)
        setError(true)

      }
    }
  };


export const generateAll = async (event, file, setFullLoading) => {
    if (file) {
      setFullLoading(true)
      await generateICF(event)
      await generateIB(event)
      await generateISIP(event)
    }
    setFullLoading(false)
  }

  export const uploadAndExtractMultipleFilesUpdated = async (randomId, setLoading, files, filesContent, setError, container, fields, setFinishedUploading) => {

    // if (!tokenResponse || !objectId) {
    //   console.log('not signed in')
    //   try {
    //     await signInUser();
    //   } catch (error) {
    //     console.log('error in authentication', error)
    //   }
      
    // }
  
    setLoading(true);
  
  
    console.log('filesContent', filesContent)
  
    try {
      // Use Promise.all to upload multiple files concurrently
      const uploadPromises = files.map(async (file,i) => {
        console.log('file here', filesContent[i])
        const formData = new FormData();
        formData.append('file_name', file.name);
        formData.append('file_content', new Blob([filesContent[i]]));
  
        var headers = new Headers();
        // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
        headers.append('container', container)
        // headers.append('X-MS-CLIENT-PRINCIPAL-ID', '050e6292-662f-4b0f-9a89-4b0beca8bed6')
  
        const requestOptions = {
          method: 'POST',
          body: formData,
          headers: headers,
        };
  
        const response = await fetch(`http://localhost:7071/api/uploadFileUpdated`, requestOptions);
  
        if (!response.ok) {
          throw new Error(`Error uploading file ${file.name}: ${response.statusText}`);
        }
  
        const result = await response.text();

        
        console.log('file name:', result)

        const myHeaders = new Headers();
        // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");
        // myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "input_path": [
            file.name
          ],
          "fields": fields,
          "input_container": "research",
          "pc_index_name": "externaldata",
          "doc_type": "protocol",
          "randomID": randomId
        });

        const requestOptions2 = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

      await fetch("http://localhost:7071/api/extractAndStoreTable", requestOptions2)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
            });
  
      await Promise.all(uploadPromises);

      
      // await delay(2000);
  
      setLoading(false);
  
      // Handle the response from the Azure Function if needed
    } catch (error) {
      console.error('Error uploading files:', error);
      setError(true);
      setLoading(false);
    }
  };


  export const readCosmosData = async (randomId) => {
    const myHeaders = new Headers();
    // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6");

    const raw = JSON.stringify({
      "randomID": randomId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body:raw
    };

    return fetch("http://localhost:7071/api/readCosmosTable", requestOptions)
      .then((response) => response.json())
      .catch((error) => console.error(error));
      }