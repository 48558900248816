import React, { useState, useEffect } from 'react';
import close from '../icons/Close_round.svg'
import sections from './sections.json'
import { getSources } from '../utils/utils';
import { Oval } from 'react-loader-spinner';


function SelectSourceComponent({handleClose, data, openInputModal, inputDoc }) {
    const [selectedSource, setSelectedSource] = useState(''); // State for the selected source
    const [metadata, setMetadata] = useState([]);
    const [sourcesLoading, setSourcesLoading] = useState(false)
    const sourceOptions = sections


  
    useEffect(() => {
      const fetchSources = async () => {
        if (selectedSource) {
          try {
            setSourcesLoading(true)
            const response = await getSources(selectedSource);
            console.log('response', response);
            setSourcesLoading(false)
            setMetadata(response);
            
          } catch (error) {
            console.error('Failed to fetch sources:', error);
          }
        }
      };
    
      fetchSources();
    }, [selectedSource, data]);
  
    function findSourceDataWithSection(data, section) {
        const result = [];
      
        data.forEach(item => {
          if (item.heading_name === section) {
            result.push(...item.source_data);
          }
        });
      
        return result;
    }
    
  
  // Usage:
  



  return (
    <div className='right-panel-content'>
        <div className='right-panel-header'>
            <h2 className='blue-text'>Select Sources</h2>
            {/* <img className='close-button' src={close} onClick={() => handleClose()}></img> */}
        </div>
      <div className='regenerate-box'>
        <div>
          <h4 className='blue-text'>Section:</h4>
          <select className='select-input' value={selectedSource} onChange={(e) => setSelectedSource(e.target.value)}>
            <option value="">Select a section</option>
            {sourceOptions.map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
          </select>
        </div>
        {!sourcesLoading && <div>
          {metadata && metadata.length > 0 && <h4 className='blue-text'>Sources:</h4>}
          <div className='source-box-container'>
            {metadata && metadata.slice(1,3).map((item, index) => (
              item.metadata?.external ?
              <>
              <div style={{cursor:'pointer'}} className='sources-box' onClick={() => openInputModal(item.metadata.page, 'https://3f693757d182022c.blob.core.windows.net/input/P13K-mTOR Pathway.pdf')}>
                    {item.metadata.source && <p className='source'>{`${item.metadata.source} p${item.metadata.page}`}</p>}
                    <div>
                        <p>{item.page_content}</p>
                    </div>
                </div>
              </> :
                // <div style={{cursor:'pointer'}} className='sources-box' onClick={() => openInputModal(item.metadata.page, inputDoc)}>
                //     {item.metadata.source && <p className='source'>{`${item.metadata.source} p${item.metadata.page}`}</p>}
                //     <div>
                //         <p>{item.page_content}</p>
                //     </div>
                // </div>
                <div style={{cursor:'pointer'}} className='sources-panel-box'>
                    {<p className='source'>{`${item.source}`}</p>}
                    <div>
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
            
            </div>
          
        </div>}
        {sourcesLoading && <div className='loading-spinner-middle' style={sourcesLoading ? { visibility: 'visible', display:'flex', justifyContent:'center', marginLeft:'100px', alignContent:'center', flexDirection:'column'} : { visibility: 'hidden' }}>
                      <Oval
                        height="80"
                        width="100"
                        color="#000000"
                        secondaryColor="#"
                        // wrapperClass="loading-spinner-right"
                        visible={true}
                      ></Oval>
                      <p style={{textAlign:'center'}} className='blue-text'>Loading...</p>
                    </div>}
      </div>
    </div>
  );
}

export default SelectSourceComponent;