import React, { useState, useRef } from 'react';
import close from '../icons/Close_round.svg';
import sections from './sections.json'
import refresh from '../icons/material-symbols-light_refresh-rounded.svg';
import { Modal } from '@mui/material';
import { MultipleFileUploader } from './MultipleFileUploader';
import { updatedRegenerateSection } from '../utils/utils';


function RegenerateSection({textRef,handleClose, handleSubmit, selectedSubcategory, setSelectedSubcategory}) {


  const subcategoryOptions = sections

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const [newFileList, setNewFileList] = useState([]); // State to store uploaded files
  const [newFileNameList, setNewFileNameList] = useState([]); // State to store uploaded file names
  const [newFileContentList, setNewFileContentList] = useState([]); // State to store uploaded file contents

  const handleUploadModalClose = () => setUploadModalOpen(false);
  const handleUploadModalOpen = () => setUploadModalOpen(true);

  const regenerate = async () => {  
    console.log('regnerating')
    await updatedRegenerateSection(selectedSubcategory, newFileContentList, textRef.current.value);
  }

  const handleMultipleFiles2 = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setNewFileList(newFiles);
      setNewFileNameList(newFiles.map((file) => file.name));
      setNewFileContentList(fileContents);
      handleUploadModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
    }
  };


  return (
    <div className='right-panel-content'>
          <div className='right-panel-header'>
            <h2 className='blue-text'>Generate Sections</h2>
            {/* <img className='close-button' src={close} onClick={() => handleClose()}></img> */}
          </div>
          <div className='regenerate-box'>
            <div>
              <div>
                <h4 className='blue-text'>Section:</h4>
                <select className='select-input' value={selectedSubcategory} onChange={(e) => setSelectedSubcategory(e.target.value)}>
                  {subcategoryOptions.map((subcategory) => (
                    <option key={subcategory} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <h4 className='blue-text'>Additional Info</h4>
                <textarea ref={textRef} placeholder='Add a note for additional context' rows="5" className='select-input'>
                </textarea>
                
              </div>
              {newFileList.length===0 ? 
              <>
                <div style={{display:'flex', justifyContent:'center'}}>
                  <div className='side-button blue' onClick={() => handleUploadModalOpen()}>
                    <p style={{textAlign:'center', color:'white'}}>Upload New Files</p>
                  </div>
                </div>
              </> :
              <>
              <h4 className='blue-text'>Files Uploaded:</h4>
              <div className='source-box-container'>
                  {newFileList.map((file) =>
                      <div className='multiple-file-upload'>
                        <p key={file.name} style={{padding: 0}}>{file.name}</p>
                      </div>
                    )}
              </div>
              </>}
              
                {selectedSubcategory && <div className='regenerate-button' onClick={handleSubmit}>
                  <img width={20} height={20} style={{marginRight:'10px', fill:'white'}} src={refresh}/>
                  <p style={{textAlign:'center'}} className='blue-text'>Regenerate</p></div>}
              </div>
                  </div>
      <Modal
        open={uploadModalOpen}
        onClose={handleUploadModalClose}>
        <div className='updated-modal-container'>
        <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20,  color: '#1F477D'}}>Upload document(s) to regnerate your protocol from:</h2>
        <MultipleFileUploader handleFiles={handleMultipleFiles2} />
        </div>
      </Modal>
                  
    </div>
  );
}

export default RegenerateSection;
