import {useEffect, useState, useRef} from 'react';
import WebViewer from '@pdftron/webviewer'

function PDFWebViewComponent({initialFile, pageNumber}) {
  const viewer = useRef(null);
  const [instance, setInstance] = useState(null);
  console.log('input file in PDFWebViewComponent', initialFile)

  

  useEffect(() => {
    WebViewer(
      {
        path: './public',
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        initialDoc: initialFile,
        extension: 'pdf',
      },
      viewer.current,
    ).then((i) => {
        setInstance(i);
        // const { docViewer } = i
        const { documentViewer, Annotations, Search } = i.Core;

        
        

        documentViewer.addEventListener('textSelected', (quads, selectedText, pageNumber) => {
            // quads will be an array of 'Quad' objects
            // text is the selected text as a string
            if (selectedText.length > 0) {
              console.log('selectedtext',selectedText, pageNumber);
            }
          });

        documentViewer.addEventListener('documentLoaded', () => {
          documentViewer.setCurrentPage(pageNumber)
          console.log('PDF doc Loaded HERE')
          const searchText = 'CLINICAL';
          const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
          const searchOptions = {
            // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
            fullSearch: false,
            // The callback function that is called when the search returns a result.
            onResult: result => {
              console.log('called this function')
              // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
              if (result.resultCode === Search.ResultCode.FOUND) {
                console.log('item found', result)
                const textQuad = result.quads[0].getPoints(); // getPoints will return Quad objects
                documentViewer.setSearchHighlightColors({
                    // setSearchHighlightColors accepts both Annotations.Color objects or 'rgba' strings
                    searchResult: new Annotations.Color(0, 0, 255, 0.5),
                    activeSearchResult: 'rgba(0, 255, 0, 0.5)'
                  });
                // now that we have the result Quads, it's possible to highlight text or create annotations on top of the text
              }
              else {
                console.log('item not found')
              }
            },
            onDocumentEnd: result => {
              console.log('called this function')
              // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
              if (result.resultCode === Search.ResultCode.FOUND) {
                console.log('item found', result)
                const textQuad = result.quads[0].getPoints(); // getPoints will return Quad objects
                // now that we have the result Quads, it's possible to highlight text or create annotations on top of the text
              }
              else {
                console.log('item not found')
              }
            },
            onError: result => {
              console.log('there was an error')
              // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
              if (result.resultCode === Search.ResultCode.FOUND) {
                console.log('item found', result)
                const textQuad = result.quads[0].getPoints(); // getPoints will return Quad objects
                // now that we have the result Quads, it's possible to highlight text or create annotations on top of the text
              }
              else {
                console.log('item not found')
              }
            }
          };
          documentViewer.textSearchInit(searchText, mode, searchOptions);
        });        
      });
  }, []);

  useEffect(() => {
    if (instance) {


      console.log('Loading new PDF document:', initialFile);
      instance.UI.loadDocument(initialFile, {extension:'pdf', enableOfficeEditing:true})

    }
 }, [initialFile,instance])

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
};


export default PDFWebViewComponent;

