import React, { useState, useEffect } from 'react';
import { Button, Container, FormControl, TextField, FormControlLabel, InputLabel, h4, Radio, RadioGroup, Slide, Autocomplete, Chip, Modal, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import CustomRadio from './RadioButton';
import { FileUploader } from './FileUploader';
import { MultipleFileUploader } from './MultipleFileUploader';
import { uploadAnswers } from '../utils/utils';
import WebViewComponent from './WebViewComponent';
import { generateProtocol } from '../utils/utils';
import { Grid } from 'react-loader-spinner';
import plus from '../icons/simple-line-icons_plus.svg';
import { newUploadMultipleFiles, writeToDatabase } from '../utils/utils';
import { IconButton } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import table from '../staticFiles/sampleTable.json'
import { DataGrid } from '@mui/x-data-grid';




const PlaygroundComponent = ({ randomId }) => {
  const [showTableSet, setShowTableSet] = useState(true);
  const [slide, setSlide] = useState(0);

  const [protocolList, setProtocolList] = useState([]);
  const [protocolNameList, setProtocolNameList] = useState([]);
  const [protocolContentList, setProtocolContentList] = useState([]);

  const [externalInfoList, setExternalInfoList] = useState([]);
  const [externalInfoNameList, setExternalInfoNameList] = useState([]);
  const [externalInfoContentList, setExternalInfoContentList] = useState([]);


  const [protocolTags, setProtocolTags] = useState([]);
  const [protocolInputValue, setProtocolInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [protocolModal, setProtocolModal] = useState(false);
  const [externalInfoModal, setExternalInfoModal] = useState(false);


  const [items, setItems] = useState([{ name: 'study_name', description: 'What is the name of this clinical trial protocol?' }, { name: 'inclusion', description: 'What are the inclusion criteria for the study?' }, { name: 'exclusion', description: 'What are the exclusion criteria for the study?' }]);
  const [newItem, setNewItem] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [finishedUploading, setFinishedUploading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  const [document, setDocument] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const [values, setValues] = useState([
    'PROTOCOL',
    'CLINICAL STUDY REPORT (CSR)',
    'INFORMED CONSENT FORM (ICF)',
    'OTHER',
  ]);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than or equal to 768px
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []); 

  

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = () => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleExit = () => {
    setSlide(0)
    setProtocolContentList([])
    setProtocolList([])
    setProtocolNameList([])
    setExternalInfoContentList([])
    setExternalInfoList([])
    setExternalInfoNameList([])
    setFinishedUploading(false)
    setEmail('')
  }

  const clearProtocolFiles = () => {
    setProtocolList([]);
    setProtocolNameList([]);
    setProtocolContentList([]);
  };

  const clearExternalFiles = () => {
    setExternalInfoList([]);
    setExternalInfoNameList([]);
    setExternalInfoContentList([]);
  };

    let text1;
    let text2;
    let text3;
    let text4;
    let text5;
    let text6;
    let text7;



    switch (document) {
        case 'PROTOCOL':
            text1 = 'How we draft a protocol:'
            text2 = (
                <span>
                For this playground, we draft sections 1-4 of a clinical trial protocol based on the&nbsp;
                <a href="https://www.fda.gov/media/164117/download" style={{ color: 'blue' }}>
                    latest ICH M11 template
                </a>.
                </span>
            );
            text3 = 'We use a synopsis of the protocol you want to draft and at least one sample protocol (an earlier phase trial protocol, a protocol from clinicaltrials.gov you want to mimic, etc...) to generate your draft.'
            text4 = 'Start by uploading the synopsis below.'
            text5 = 'Choose synopsis to upload';
            text6 = 'Upload at least one sample protocol:'
            text7 = 'Choose previous protocol(s) to upload'
            break;
        case 'CLINICAL STUDY REPORT (CSR)':
            text1 = 'How we draft a CSR:'
            text2 = (
                <span>
                For this playground, we draft sections 1-3 of a CSR based on the&nbsp;
                <a href="https://www.transceleratebiopharmainc.com/assets/clinical-content-reuse-solutions/" style={{ color: 'blue' }}>
                    TransCelerate template
                </a>.
                </span>
            );
            text3 = 'We use the protocol associated with the CSR, along with a Statistical Analysis Plan (SAP) to generate your draft.'
            text4 = 'Start by uploading the protocol below.'
            text5 = 'Choose protocol to upload';
            text6 = 'Upload Statistical Analysis Plan:'
            text7 = 'Choose Statistical Analysis Plan to upload'
            break;
        case 'INFORMED CONSENT FORM (ICF)':
            text1 = 'How we draft the Informed Consent Form:'
            text2 = (
                <span>
                For this playground, we draft an informed consent form based on the&nbsp;
                <a href="https://ohsrp.nih.gov/confluence/display/ohsrp/Consent+Templates+and+Guidance?desktop=true&macroName=report-table" style={{ color: 'blue' }}>
                    NIH NIA template
                </a>.
                </span>
            );
            text3 = "We use a protocol or investigator's brochure to generate your draft."
            text4 = 'Start by uploading the protocol or IB below.'
            text5 = 'Choose protocol or IB to upload';
            text6 = 'Upload any other documents for the ICF (optional):'
            text7 = 'Choose other documents to upload'
            break;
        case 'OTHER':
            text1 = 'How we draft other documents:'
            text2 = (
                <span>
                For this playground, we draft sections 1-4 of a clinical trial protocol based on the&nbsp;
                <a href="https://www.fda.gov/media/164117/download" style={{ color: 'blue' }}>
                    latest ICH M11 template
                </a>.
                </span>
            );
            text3 = 'We use a sample of the document you want to draft and at least one other document with information specific to the product to generate your draft.'
            text4 = 'Start by uploading the document below.'
            text5 = 'Choose document to upload';
            text6 = 'Upload at least one additional document:'
            text7 = 'Choose document(s) to upload'
            break;
        default:
            console.log('values swithced', values)
        }









  const handleAddItem = () => {
    if (newItem && newDescription) {
      setItems([...items, { name: newItem, description: newDescription }]);
      setNewItem('');
      setNewDescription('');
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    setItems(items.filter(item => item !== itemToRemove));
  };


  const handleProtocolInputChange = (event) => {
    setProtocolInputValue(event.target.value);
  };

  const handleProtocolInputKeyDown = (event) => {
    if (event.key === 'Enter' && protocolInputValue) {
      setProtocolTags([...protocolTags, protocolInputValue]);
      setProtocolInputValue('');
    }
  };

  const handleProtocolDelete = (tagToDelete) => () => {
    setProtocolTags(protocolTags.filter((tag) => tag !== tagToDelete));
  };


  const handleContinue = (val) => {
    setSlide(slide + val);
  }

  const handleProtocolModalOpen = () => setProtocolModal(true);
  const handleProtocolModalClose = () => setProtocolModal(false);

  const handleExternalInfoModalOpen = () => setExternalInfoModal(true);
  const handleExternalInfoModalClose = () => setExternalInfoModal(false);

  const handleProtocolFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setProtocolList(newFiles);
      setProtocolNameList(newFiles.map((file) => file.name));
      setProtocolContentList(fileContents);
      handleProtocolModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const handleExternalInfoFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setExternalInfoList(newFiles);
      setExternalInfoNameList(newFiles.map((file) => file.name));
      setExternalInfoContentList(fileContents);
      handleExternalInfoModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  }

  return (
    <div>
      <div className='main-panel'>
        <Slide direction="up" in={slide === 0} mountOnEnter unmountOnExit>
          <div>
            {showTableSet && <div style={{ marginLeft: '20px' }}>
              <div>
                <p className='button-text-initial' style={{ width: "100%", alignContent: 'center' }}>Welcome to the Artos playground!</p>

                <p className='button-text-initial' style={{ width: "100%", fontSize: 20, alignContent: 'center' }}>This is meant to give a sense for what low-effort document-drafting looks like.</p>
                <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'center', color: '#1F477D' }}>
                  If you're interested in custom templates, faster outputs, or using this more seriously, <a href="mailto:info@artosai.com" style={{ color: 'blue' }}>email us</a>.
                </p>
                {/* <p className='button-text-initial' style={{ fontSize:20, textAlign: 'left'}}>Get drafts of clinical trial documents in under an hour, powered by AI.</p> */}
              </div>
            </div>}
            <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '80vw', flexDirection: 'column' }}>
              {values.map((value, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='upload-grey' onClick={() => {
                  setDocument(value);
                  handleContinue(1);
                }}>
                  <p style={{ textAlign: 'center' }} className='blue-button-text'>{value}</p>
                </div>
              ))}
            </div>
          </div>
        </Slide>
        <Slide direction="up" in={slide === 1} mountOnEnter unmountOnExit>
          <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', flexDirection: 'column' }}>
            <div className='literature-search'>
              <div className='source-select'>
                
                <div style={{ backgroundColor: '#ECECEC', margin: '20px', width: "60%", padding: '20px', alignItems: 'center', alignSelf: 'center' }}>
                  <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'center' }}>{text1}</p>
                  <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'left', color: 'black' }}>
                    {text2}
                  </p>
                  <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'left', color: 'black' }}>{text3}</p>
                  <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'left', color: 'black' }}>{text4}</p>
                </div>

                <p className='button-text-initial' style={{ fontSize: 20 }}>Upload document:</p>
                <div className='source-button-container'>

                  {protocolList.length === 0 ?
                    <>
                      <button key='source-button' onClick={() => handleProtocolModalOpen()} className="source-button" type="submit">
                        <p className='button-text-initial' style={{ fontSize: 20 }}>Click here to upload</p>
                      </button>
                    </> :
                    <>
                      <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                      <div style={{maxHeight:'200px', overflowY:'auto'}}>
                        {protocolList.map((file) =>
                            <div className='multiple-file-upload'>
                            <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                            </div>
                        )}
                      </div>
                      <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <button className="source-button" onClick={() => clearProtocolFiles()} style={{ padding: '2px' }}>
                          <p style={{ fontSize: '20px' }} className='blue-text'>Clear Files</p>
                        </button>
                      </div>
                    </>}
                    
                </div>
                {protocolList.length >= 1 && <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                  handleContinue(1)
                }}>
                  <p style={{ fontSize: '20px' }} className='button-text-white'>Next</p>
                </div>}
                {protocolList.length >= 1 && <div className='source-button-container' onClick={async () => {
                  handleContinue(-1)
                }}>
                  <div className='upload-green' style={{ marginTop: 10 }}>
                    <p style={{ fontSize: '20px' }} className='button-text-white'>Back</p>
                  </div>
                </div>}
                <div style={{marginTop:'50px', cursor:'pointer'}}onClick={() => handleExit()}>
                        <p style={{textAlign:'center'}}className='blue-text'>Back to Home</p>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide direction="up" in={slide === 2} mountOnEnter unmountOnExit>
          <div className='initial-upload-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw' }}>
            <div className='literature-search'>
              <div className='source-select'>
                <p className='button-text-initial' style={{ fontSize: 20 }}>{text6}</p>
                <div className='source-button-container'>

                  {externalInfoList.length === 0 ?
                    <>
                      <button key='source-button' onClick={() => handleExternalInfoModalOpen()} className="source-button" type="submit">
                        <p className='button-text-initial' style={{ fontSize: 20 }}>Click here to upload</p>
                      </button>
                    </> :
                    <>
                      <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                      <div style={{maxHeight:'200px', overflowY:'auto'}}>
                      {externalInfoList.map((file) =>
                        <div className='multiple-file-upload'>
                          <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                        </div>
                      )}
                      </div>
                      <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <button className="source-button" onClick={() => clearExternalFiles()} style={{ padding: '2px' }}>
                          <p style={{ fontSize: '20px' }} className='blue-text'>Clear Files</p>
                        </button>
                      </div>
                    </>}
                </div>
                {externalInfoList.length >= 1 && <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                  handleContinue(1)

                }}>
                  <p style={{ fontSize: '20px' }} className='button-text-white'>Next</p>
                </div>}
                {externalInfoList.length >= 1 && <div className='source-button-container' onClick={async () => {
                  handleContinue(-1)
                }}>
                  <div className='upload-green' style={{ marginTop: 10 }}>
                    <p style={{ fontSize: '20px' }} className='button-text-white'>Back</p>
                  </div>
                </div>}
                <div style={{marginTop:'50px', cursor:'pointer'}}onClick={() => handleExit()}>
                        <p style={{textAlign:'center'}}className='blue-text'>Back to Home</p>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <Slide direction="up" in={slide === 3} mountOnEnter unmountOnExit>
          <div className='initial-upload-container' style={loading ? { display: 'none' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', flexDirection: 'column' }}>
            <div>
              <p style={{ textAlign: 'center', fontSize: 20 }} className='button-text-initial' >Your request is ready to be queued!</p>
              <p style={{ textAlign: 'center', fontSize: 20 }} className='button-text-initial'>Enter your email to recieve your draft when it's ready (~1hr).</p>
              <TextField
                error={emailError}
                helperText={emailError ? "Invalid email" : ""}
                label="Email"
                value={email}
                style={{ width: '600px' }} // Add this line
                onChange={(event) => {
                  handleEmailChange(event);
                  validateEmail(event.target.value);
                }}
                onBlur={validateEmail}
              />
              {!emailError && email && <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                setLoading(true)
                await newUploadMultipleFiles(randomId, protocolList, protocolContentList, 'internal');
                await newUploadMultipleFiles(randomId, externalInfoList, externalInfoContentList, 'external');
                await writeToDatabase(randomId, protocolNameList, externalInfoNameList, document, email);
                setLoading(false)
                setFinishedUploading(true)

              }}>
                <p style={{ fontSize: '20px' }} className='button-text-white'>Submit request</p>
              </div>}
              <p className='button-text-initial' style={{ fontSize: 20, textAlign: 'center', color: 'black' }}>
                To learn more, <a href="mailto:info@artosai.com" style={{ color: 'blue' }}>contact us</a>.
              </p>
              <div style={{marginTop:'50px', cursor:'pointer'}}onClick={() => handleExit()}>
                        <p style={{textAlign:'center'}}className='blue-text'>Back to Home</p>
                </div>
            </div>
          </div>
        </Slide>
        <Modal
          open={protocolModal}
          onClose={handleProtocolModalClose}>
          <div className='updated-modal-container'>
            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload previous protocol data</h2>
            <MultipleFileUploader handleFiles={handleProtocolFiles} />
          </div>
        </Modal>
        <Modal
          open={protocolModal}
          onClose={handleProtocolModalClose}>
          <div className='updated-modal-container'>
            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>{text5}</h2>
            <MultipleFileUploader handleFiles={handleProtocolFiles} />
          </div>
        </Modal>
        <Modal
          open={externalInfoModal}
          onClose={handleExternalInfoModalClose}>
          <div className='updated-modal-container'>
            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>{text7}</h2>
            <MultipleFileUploader handleFiles={handleExternalInfoFiles} />
          </div>
        </Modal>
        <Modal
          open={finishedUploading}
          onClose={() => setFinishedUploading(false)}>
          <div className='updated-modal-container'>
            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Your request has been submitted! You'll receive an email from info@artosai.com when your draft is ready.</h2>
            <div onClick={() => {
              handleExit()
            }} className='upload-blue'>
              <p style={{ textAlign: 'center' }} className='button-text-white'>Done</p>
            </div>
          </div>
        </Modal>
        <Modal
          open={isMobile}>
          <div className='updated-modal-container'>
            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Sorry, this site only works on desktop.</h2>
            {/* <div onClick={() => {
              handleExit()
            }} className='upload-blue'>
              <p style={{ textAlign: 'center' }} className='button-text-white'>Done</p>
            </div> */}
          </div>
        </Modal>
        <div className='initial-upload-container' style={loading ? { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', flexDirection: 'column' } : { display: 'none' }}>
          {loading && <div className='loading-spinner-middle' style={loading ? { visibility: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' } : { visibility: 'hidden' }}>
            <Grid
              height="80"
              width="80"
              color="#1DC989"
              secondaryColor="#"
              // wrapperClass="loading-spinner-right"
              visible={true}
            ></Grid>
            <p className='green-text'>Loading...</p>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default PlaygroundComponent;