import React from 'react'
import { useState, useEffect, useRef, useReducer } from 'react';
import './App.css';
import "@fontsource/dm-sans"; // Defaults to weight 400
import { FileUploader } from "./components/FileUploader";
import close from './icons/Close_round.svg'
import plus from './icons/simple-line-icons_plus.svg';
// import plus from './icons/plus-icon.svg';
import chat from './icons/lets-icons_chat-light.svg';
import sourceIcon from './icons/carbon_connect.svg';
import refresh from './icons/material-symbols-light_refresh-rounded.svg';
import logo from './icons/artos-LOGO.svg';
import errorIcon from './icons/error.svg';
import LoadingOverlay from 'react-loading-overlay';
import DocViewer, { PDFRenderer, MSDocRenderer, DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from '@mui/material/Modal';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import WebViewComponent from './components/WebViewComponent';
import SearchColumn from './components/SearchColumn';
import RegenerateSection from './components/RegenerateSection';
import ChatSection from './components/ChatSection';
import SourcePanel from './components/SourcePanel';
import ProtocolForm from './components/ProtocolForm';
import { viewExistingFiles, testAuth, uploadFile, handleSearch, generateICF, generateIB, generateIB2, generateISIP, generateAll, uploadMultipleFiles, getQuestions, regenerateSection, createVectorStore, saveProtocol, uploadMultipleFilesUpdated, searchLiterature, searchProtocols, updatedGenerateProtocol, extractAndStore, createOutputVectorStore, updatedRegenerateSection } from './utils/utils';
import data from './source.json'
import { Oval } from 'react-loader-spinner';
import PDFWebViewComponent from './components/PDFWebViewComponent';
import DocumentContainer from './components/DocumentContainer';
import DocumentBoxInternal from './components/DocumentBoxInternal';
import { Grid } from 'react-loader-spinner';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide, Chip } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { MultipleFileUploader } from './components/MultipleFileUploader';
import { clear } from '@testing-library/user-event/dist/clear';
import templateInfo from './components/templateInfo.json'
import { DataGrid } from '@mui/x-data-grid';
import documentSet from './staticFiles/documentSets.json'
import sourceData from './staticFiles/sourceData.json'
import { v4 as uuidv4 } from 'uuid';
import ResearchComponent from './components/ResearchComponent';
import PlaygroundComponent from './components/PlaygroundComponent';







function App() {

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null)
  const [fileObj, setFileObj] = useState(null)
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null)
  const [fileKey, setFileKey] = useState(0);
  const [slide, setSlide] = useState(0);



  let docs = [
    { uri: uploadedFileUrl, fileType: 'pdf' },
  ];

  const [docList, setDocList] = useState([...docs])
  const [ICFResultFile, setICFResultFile] = useState(null);
  const [IBResultFile, setIBResultFile] = useState(null)
  const [ISIPFile, setISIPFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [secondLoading, setSecondLoading] = useState(false)
  const [thirdLoading, setThirdLoading] = useState(false)
  const [fullLoading, setFullLoading] = useState(false)
  const [error, setError] = useState(false)
  const [generateModalOpen, setGenerateModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)

  const [viewerKey, setViewerKey] = useState(0); //Viewer key state
  const [active, setActive] = useState(6)
  const [searchResults, setSearchResults] = useState(null)
  const [rightBarLoading, setRightBarLoading] = useState(false)
  const [protocolFile, setProtocolFile] = useState(null)
  const [sourceFile, setSourceFile] = useState(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState(''); //


  const [inputFile, setInputFile] = useState(null); // State to store uploaded files
  const [openInputFile, setOpenInputFile] = useState(false);
  const [activePageNumber, setActivePageNumber] = useState(1);

  const textRef = useRef(null);
  const searchRef = useRef()
  const [uploadButton, setShowUploadButton] = useState(false)
  const [protocolGeneration, setProtocolGeneration] = useState(false)
  const [multipleFileList, setMultipleFileList] = useState([]); // State to store uploaded files
  const [multipleFileNameList, setMultipleFileNameList] = useState([]); // State to store uploaded files
  const [multipleFileContentList, setMultipleFileContentList] = useState([]); // State to store uploaded files

  const [multipleFileList2, setMultipleFileList2] = useState([]); // State to store uploaded files
  const [multipleFileNameList2, setMultipleFileNameList2] = useState([]); // State to store uploaded files
  const [multipleFileContentList2, setMultipleFileContentList2] = useState([]); // State to store uploaded files

  const [templateList, setTemplateList] = useState([]); // State to store uploaded files
  const [templateNameList, setTemplateNameList] = useState([]); // State to store uploaded files
  const [templateContentList, setTemplateContentList] = useState([]); // State to store uploaded files

  const [literatureList, setLiteratureList] = useState([]); // State to store uploaded files
  const [literatureNameList, setLiteratureNameList] = useState([]); // State to store uploaded files
  const [literatureContentList, setLiteratureContentList] = useState([]); // State to store uploaded files

  const [protocolList, setProtocolList] = useState([]); // State to store uploaded files
  const [protocolNameList, setProtocolNameList] = useState([]); // State to store uploaded files
  const [protocolContentList, setProtocolContentList] = useState([]); // State to store uploaded files

  const [questionList, setQuestionList] = useState([])
  const [previousDocs, setPreviousDocs] = useState([])
  const [activeDoc, setActiveDoc] = useState(null);
  const [protocolName, setProtocolName] = useState(null);
  const [hasLink, setHasLink] = useState(false);

  const [litInputValue, setLitInputValue] = useState('');
  const [litTags, setLitTags] = useState([]);


  const [protocolInputValue, setProtocolInputValue] = useState('');
  const [protocolTags, setProtocolTags] = useState([]);

  const [uploadModal, setUploadModal] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [literatureModal, setLiteratureModal] = useState(false);
  const [protocolModal, setProtocolModal] = useState(false);
  const [templateFile, setTemplateFile] = useState('https://3f693757d182022c.blob.core.windows.net/intermediate/Prot_SAP_000.docx')

  const [showDocumentSet, setShowDocumentSet] = useState(true);
  const [demo, setDemo] = useState(false)
  const [randomId, setRandomId] = useState('');


  const delay = ms => new Promise(res => setTimeout(res, ms));
  useEffect(() => {
    setRandomId(uuidv4().replace(/-/g, ''));
  }, []); // 
  console.log('randomId', randomId)

  // const columns = [
  //   { field: 'header', headerName: 'Header', width: 300, editable: false },
  //   { field: 'content_type', headerName: 'Content Type', width: 130, editable: true, valueGetter: (params) => {
  //     if (params.value === 'key_content') {
  //       return 'Key Content';
  //     } else if (params.value === 'boiler_plate') {
  //       return 'Boiler Plate';
  //     } else if (params.value === 'formatting')
  //       return "Formatting"
  //     else {
  //       return params.value;
  //     }
  //   } },
  //   { field: 'description', headerName: 'Description', width: 500, editable: true },
  // ];

  // const rows = templateInfo.map((item, index) => ({
  //   id: index,
  //   header: item.header,
  //   content_type: item.content_type,
  //   description: item.description,
  // }));



  // Resize the viewer when the webviewer is loaded
  const [divClicked, setDivClicked] = useState(false);

  const handleContinue = (increment) => {
    setSlide((prevStep) => prevStep + increment);
  };

  const handleLitInputChange = (event) => {
    setLitInputValue(event.target.value);
  };

  const handleLitInputKeyDown = (event) => {
    if (event.key === 'Enter' && litInputValue) {
      setLitTags([...litTags, litInputValue]);
      setLitInputValue('');
    }
  };

  const handleLitDelete = (tagToDelete) => () => {
    setLitTags(litTags.filter((tag) => tag !== tagToDelete));
  };

  const handleProtocolInputChange = (event) => {
    setProtocolInputValue(event.target.value);
  };

  const handleProtocolInputKeyDown = (event) => {
    if (event.key === 'Enter' && protocolInputValue) {
      setProtocolTags([...protocolTags, protocolInputValue]);
      setProtocolInputValue('');
    }
  };

  const handleProtocolDelete = (tagToDelete) => () => {
    setProtocolTags(protocolTags.filter((tag) => tag !== tagToDelete));
  };





  // useEffect(() => {

  //   const getResult = async () => {
  //     setThirdLoading(true)
  //     const result = await viewExistingFiles().then((res) => {
  //       console.log('res', res)
  //       setPreviousDocs(res)
  //       setThirdLoading(false)
  //       setFileKey(Date.now());
  //     });
  //   }

  //   getResult();



  // }, []);


  const handleUpload = async (setLoading, fileName, file, setUploadedFileUrl, setError) => {
    console.log('uploading')
    await uploadFile(setLoading, fileName, file, fileObj, setUploadedFileUrl, setError);

    console.log('finished uploading')
  }

  const handleMultipleUpload = async (setLoading, fileList, filesContent, setError, setQuestionList) => {
    console.log('uploading mutiple files')
    await uploadMultipleFiles(setLoading, fileList, filesContent, setError);
    await getQuestions(setLoading, fileList[0].name, setError, setQuestionList)
    console.log('finished uploading mutiple files')
  }

  const regenerateSubmit = async () => {
    if (!protocolFile) {
      console.log('textRef', textRef.current.value)
      console.log('protocolName', protocolName)
      await regenerateSection(setSecondLoading, protocolName, selectedSubcategory, textRef.current.value, setError, setProtocolFile, setSourceFile, setFileKey, setHasLink)
      setHasLink(true)
      // await saveProtocol(protocolName)
    } else {
      const fileName = protocolFile.split("/").pop();
      console.log('protocol file name:', fileName);
      await regenerateSection(setSecondLoading, fileName, selectedSubcategory, textRef.current.value, setError, setProtocolFile, setSourceFile, setFileKey, setHasLink)
      setHasLink(true)
      // await saveProtocol(fileName)
    }

    handleFileContentChange();

    textRef.current.value = ''
  }

  const regenerateSubmit2 = async () => {
    setSecondLoading(true)
    const newProtocol = await updatedRegenerateSection(setLoading, protocolFile, selectedSubcategory, textRef.current.value, setError);
    console.log('newProtocol', newProtocol)
    setProtocolFile(newProtocol);
    setSecondLoading(false)
    textRef.current.value = ''
  }




  // changes key to re-render webview
  const handleFileContentChange = () => {
    setFileKey(Date.now());
  };


  const showProtocolForm = () => {
    setProtocolGeneration(true)
    setShowUploadButton(false)
  }

  const showUploadButton = () => {
    setShowUploadButton(true)
    setProtocolGeneration(false)
  }


  const handleSubmit = async (e) => {
    const value = textRef.current.value
    console.log('text ref', value)
    // await generateIB2(e, file, setLoading, handleGenerateModalClose, setError, fileName, textRef, selectedSubcategory, setIBResultFile, setActive)
  }

  const isActive = (value) => {
    if (active === value) {
      return true
    }
    return false
  }

  const handleGenerateModalOpen = () => setGenerateModalOpen(true);
  const handleGenerateModalClose = () => setGenerateModalOpen(false);

  const handleWarningModalOpen = () => setWarningModalOpen(true);
  const handleWarningModalClose = () => setWarningModalOpen(false);

  const handleUploadModalOpen = () => setUploadModal(true);
  const handleUploadModalClose = () => setUploadModal(false);

  const handleTemplateModalOpen = () => setTemplateModal(true);
  const handleTemplateModalClose = () => setTemplateModal(false);

  const handleLiteratureModalOpen = () => setLiteratureModal(true);
  const handleLiteratureModalClose = () => setLiteratureModal(false);

  const handleProtocolModalOpen = () => setProtocolModal(true);
  const handleProtocolModalClose = () => setProtocolModal(false);






  const makeActive = (docName, index) => {
    console.log('docName', docName)
    setProtocolName(docName)
    setActiveDoc(index)
  }

  const handleMultipleFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setMultipleFileList(newFiles);
      setMultipleFileNameList(newFiles.map((file) => file.name));
      setMultipleFileContentList(fileContents);
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const handleMultipleFiles2 = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setMultipleFileList2(newFiles);
      setMultipleFileNameList2(newFiles.map((file) => file.name));
      setMultipleFileContentList2(fileContents);
      handleUploadModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const handleTemplateFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setTemplateList(newFiles);
      setTemplateNameList(newFiles.map((file) => file.name));
      setTemplateContentList(fileContents);
      handleTemplateModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const handleLiteratureFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setLiteratureList(newFiles);
      setLiteratureNameList(newFiles.map((file) => file.name));
      setLiteratureContentList(fileContents);
      handleLiteratureModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const handleProtocolFiles = (files, fileContents) => {
    try {
      // Handle saving the file content or any other logic as needed for each file
      const newFiles = [];
      console.log('files here', files)

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const content = fileContents[i];
        newFiles.push({ name: file.name, content });
        console.log('newFiles', newFiles)
      }

      // Update React state with the array of file objects
      setProtocolList(newFiles);
      setProtocolNameList(newFiles.map((file) => file.name));
      setProtocolContentList(fileContents);
      handleProtocolModalClose();
    } catch (e) {
      console.log('Error in handling files', e);
      setError(true);
    }
  };

  const openInputFileModal = (pageNumber, inputFileName) => {
    setOpenInputFile(true)
    setInputFile(inputFileName)
    setActivePageNumber(pageNumber)
  }


  // const updatedHandleMultipleFiles = (files) => {
  //   try {
  //     // Handle saving the file content or any other logic as needed for each file
  //     const fileNames = [];
  //     const fileList = []

  //     console.log('updated files here', files)

  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       fileNames.push(file.name);
  //       fileList.push(file)
  //     }

  //     // Update React state with the array of file objects
  //     setMultipleFileList(fileList);
  //     setMultipleFileNameList(fileNames);
  //   } catch (e) {
  //     console.log('Error in handling files', e);
  //     setError(true);
  //   }
  // };


  const unselectFile = () => {
    setFile(null)
    setFileName("")
    setICFResultFile(null)
    setUploadedFileUrl(null)
    window.location.reload(true);
  }

  const resetAllFiles = () => {
    setMultipleFileContentList([]);
    setMultipleFileList([]);
    setMultipleFileNameList([]);
  }

  const resetDocs = () => {
    setActiveDoc(null)
    setActive(0)
    setProtocolFile(null)
  }

  const clearSourceFiles = () => {
    setMultipleFileContentList2([]);
    setMultipleFileList2([]);
    setMultipleFileNameList2([]);
  }

  const clearTemplateFiles = () => {
    setTemplateList([]);
    setTemplateContentList([]);
    setTemplateNameList([]);
  }

  const clearProtocolFiles = () => {
    setProtocolList([]);
    setProtocolContentList([]);
    setProtocolNameList([]);
  }

  const clearLiteratureFiles = () => {
    setLiteratureList([]);
    setLiteratureContentList([]);
    setLiteratureNameList([]);
  }

  return (
    <LoadingOverlay
      active={secondLoading}
      spinner
      text='Generating document...'>
      <div className='main-body'>
        <div className='left-panel'>
          <img className='logo' src={logo} />
          <div className='left-files-container'>
            {/* <div onClick={() => {
              setActive(0)
              resetDocs()
              setDivClicked(false)
              setSlide(0);
              clearSourceFiles();
              clearTemplateFiles();
              clearProtocolFiles();
              clearLiteratureFiles();
              setShowDocumentSet(true);
            }} className='file-select-button'>
              <p style={isActive(0) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', justifyContent: 'flex-start', marginBottom: "2px" }}>Home</p>
            </div> */}
            {/* <div onClick={() => setActive(2)} className='file-select-button'>
              <p style={isActive(2) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', marginBottom: "2px" }}>Alerts</p>
            </div> */}
            {/* <div onClick={() => setActive(3)} className='file-select-button'>
              <p style={isActive(3) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', marginBottom: "2px" }}>Templates</p>
            </div> */}
            {/* <div onClick={() => setActive(4)} className='file-select-button'>
              <p style={isActive(4) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', marginBottom: "2px" }}>Research</p>
            </div> */}
            {/* <div onClick={() => setActive(5)} className='file-select-button'>
              <p style={isActive(5) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', marginBottom: "2px" }}>Account</p>
            </div> */}
            <div onClick={() => {
              setActive(6);
            }
            } className='file-select-button'>
              <p style={isActive(6) ? { color: '#1DC989', fontSize: '20px', marginBottom: "2px" } : { color: 'white', fontSize: '20px', marginBottom: "2px" }}>Home</p>
            </div>
          </div>
          <div className='left-button-container'>
          </div>
        </div>
        {isActive(0) && <div className='main-panel'>
          <div style={{marginLeft:'20px'}}>
          {showDocumentSet && <div>
            <p className='button-text-initial'>Your document sets:</p>
          </div>}
          </div>
          {activeDoc === null && !protocolFile &&
            <>
              <p style={{marginLeft:'20px'}} className='button-text-initial'>IND for ABC-123</p>

              <Slide direction="up" in={slide === 0} mountOnEnter unmountOnExit>
                <div className='initial-upload-container'>
                  <div style={{marginLeft:'20px',  display: 'flex', justifyContent: 'center'}} className='initial-upload-button'>
                    <button key='upload-button' onClick={() => handleContinue(1)} className="upload-initial" type="submit">
                      {!showDocumentSet && <div style={{ marginTop: 20, width: '100%', borderRadius: 10, backgroundColor: '#1DC989', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <img height={40} width={40} style={{ marginRight: '20px', fill: 'white' }} src={plus}></img>
                        <p className='button-text-initial' style={{ color: 'white', fontSize: 20 }}>Create new document </p>
                      </div>}
                    </button>
                  </div>
                </div>
              </Slide>

              <Slide direction="up" in={slide === 1} mountOnEnter unmountOnExit>
                <div className='initial-upload-container'>
                  <div className='template-select'>
                    <p className='button-text-initial' style={{ textAlign: 'left', marginBottom: 30, marginLeft:20 }}>Pick a template to start from:</p>
                    <div className='template-button-container'>
                      <button key='ICH-template' onClick={() => handleContinue(3)} className="template-button" type="submit">
                        <p className='button-text-initial'>ICH M11 Template</p>
                      </button>
                      <button key='transcelerate-template' onClick={() => handleContinue(3)} className="template-button" type="submit">
                        <p className='button-text-initial'>Transcelerate Template</p>
                      </button>
                      <button key='transcelerate-template' onClick={() => handleContinue(3)} className="template-button" type="submit">
                        <p className='button-text-initial'>Company template 1</p>
                      </button>
                      <button key='transcelerate-template' onClick={() => handleContinue(1)} style={{ marginTop: '100px', border: 'white', borderRadius: 10, backgroundColor: 'white' }} type="submit">
                        <p className='button-text-initial' style={{ color: "#1F477D" }}>Create new template</p>
                      </button>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide direction="up" in={slide === 2} mountOnEnter unmountOnExit>
                <div className='initial-upload-container'>
                  <div className='template-select'>
                    <p style={{ textAlign: 'left', marginLeft:'20px' }} className='button-text-initial'>Upload a template or a sample document:</p>
                    <div className='template-button-container'>
                      {/* <button key='ICH-template' onClick={() => handleContinue(3)} className="template-button" type="submit">
                        <p className='button-text-initial'>ICH M11 Template</p>
                      </button> */}
                      {templateList.length === 0 ?
                        <>
                          <div className='template-select'></div>
                          <button key='source-button' onClick={() => handleTemplateModalOpen()} className="template-button" style={{ alignItems: 'center' }} type="submit">
                            <p className='button-text-initial'>Click to upload</p>
                          </button>
                        </>
                        : <>
                          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>File Selected:</h2>
                          {templateList.map((file) =>
                            <div className='multiple-file-upload'>
                              <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                            </div>
                          )}
                          <div className='upload-green' style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }} onClick={async () => {
                            await uploadMultipleFilesUpdated(setLoading, templateList, templateContentList, setError, 'input');
                            handleContinue(1)
                          }}>
                            <p className='button-text-white' style={{ fontSize: 20 }}>Continue</p>
                          </div>
                        </>}
                    </div>
                  </div>

                  {/* {templateList.length === 0 ?
                    <>
                      <div className='template-select'></div>
                      <button key='source-button' onClick={() => handleTemplateModalOpen()} className="template-button" style={{ alignItems: 'center' }} type="submit">
                        <p className='button-text-initial'>Click to upload</p>
                      </button>
                    </>
                    : <>
                      <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>File Selected:</h2>
                      {templateList.map((file) =>
                        <div className='multiple-file-upload'>
                          <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                        </div>
                      )}
                      <div className='upload-green' style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }} onClick={async () => {
                        await uploadMultipleFilesUpdated(setLoading, templateList, templateContentList, setError, 'input');
                        handleContinue(1)
                      }}>
                        <p className='button-text-white'>Create Template From Source</p>
                      </div>
                    </>} */}

                </div>
              </Slide>
              <Slide direction="up" in={slide === 3} mountOnEnter unmountOnExit>
                <div>
                  <p style={{marginLeft:'20px'}} className='button-text-initial'>Template Overview:</p>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='webviewContainer' style={templateFile ? { height: '600px', width: '800px', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                      <WebViewComponent initialFile={templateFile}></WebViewComponent>
                    </div>
                    <div className='upload-green' style={{ marginTop: 10 }} onClick={() => handleContinue(1)}>
                      <p className='button-text-white'>Save Template</p>
                    </div>
                    {/* <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                  /> */}
                  </div>

                </div>
              </Slide>
              <Slide direction="up" in={slide === 4} mountOnEnter unmountOnExit>
                <div className='initial-upload-container'>
                  {!loading && <div className='source-select'>
                    <p className='button-text-initial' style={{ textAlign: 'left'}}>Select your source data</p>
                    {multipleFileList2.length === 0 ?
                      <>
                        <div className='source-button-container'>
                          <button key='source-button' onClick={() => handleUploadModalOpen()} className="source-button" type="submit">
                            <p className='button-text-initial'>Upload Your Own Files</p>
                          </button>
                          <button key='source-button' onClick={() => {
                            handleUploadModalOpen()
                            setDemo(true)
                          }} className="source-button" type="submit">
                            <p className='button-text-initial'>Use Demo Source</p>
                          </button>
                        </div>
                      </> :
                      <>
                        <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                        {multipleFileList2.map((file) =>
                          <div className='multiple-file-upload'>
                            <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                          </div>
                        )}
                        <div>
                          <button className="source-button" onClick={() => clearSourceFiles()} style={{ padding: '2px' }}>
                            <p style={{fontSize:'20px'}} className='blue-text'>Clear Files</p>
                          </button>
                        </div>
                      </>}
                      {!loading && multipleFileList2.length >= 1 && <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                    if (!demo) {
                      setLoading(true)
                      await uploadMultipleFilesUpdated(setLoading, multipleFileList2, multipleFileContentList2, setError, 'input');

                      console.log('multipleFileList2', multipleFileList2)
                      const flatList = multipleFileList2.map(item => item.name);
                      console.log('flatlist', flatList)
                      await extractAndStore(flatList, randomId, setLoading);
                      // await extractAndStore(flatList, '3f693757d182022c', setLoading)
                      // setLoading(false)
                      handleContinue(1)
                    } else {
                      await uploadMultipleFilesUpdated(setLoading, multipleFileList2, multipleFileContentList2, setError, 'input');
                      handleContinue(1)
                    }
                }}>
                  <p style={{fontSize:'20px'}} className='button-text-white'>Next</p>
                </div>}

                  </div>}

                  
            </div>
          </Slide>
          {/* <Slide direction="up" in={slide === 5} mountOnEnter unmountOnExit>
            {!loading && <div className='initial-upload-container'>
            <div className='source-select'>
                  <p className='button-text-initial'>Upload supporting literature:</p>
                  <div className='source-button-container'>
                    
                    {literatureList.length===0 ? 
                    <>
                    <button key='source-button' onClick={() => handleLiteratureModalOpen()} className="source-button" type="submit">
                      <p className='button-text-initial'>Upload Files</p>
                    </button>
                    </> :
                    <>
                    <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                    {literatureList.map((file) =>
                      <div className='multiple-file-upload'>
                        <p key={file.name} style={{padding: 0}}>{file.name}</p>
                      </div>
                    )}
                    </>}
                  </div>
            </div>
            <div className='literature-search'>
                <p style={{textAlign:'center'}} className='button-text-initial'>Or search the web</p>
                <p className='button-text-initial'>Published literature keywords:</p>

                <Autocomplete
                  multiple
                  value={litTags}
                  onChange={(event, newLitTags) => {
                    setLitTags(newLitTags);
                  }}
                  freeSolo
                  options={[]}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{minWidth: '600px'}}
                      variant="outlined"
                      value={litInputValue}
                      onChange={handleLitInputChange}
                      onKeyDown={handleLitInputKeyDown}
                    />
                  )}
                />
                <div className='upload-green'  onClick={async () => {
                  if (literatureList.length > 0) {
                  await uploadMultipleFilesUpdated(setLoading, literatureList, literatureContentList, setError, 'literature');
                  }
                  if (litTags.length > 0) {
                  await searchLiterature(setLoading, litTags, setError);
                  }
                  handleContinue(1)

                }}>
                  <p style={{fontSize:'20px'}} className='button-text-white'>Next</p>
                </div>
                </div>
                
            </div>}
          </Slide> */}
          <Slide direction="up" in={slide === 5} mountOnEnter unmountOnExit>
            <div className='initial-upload-container'>            
                {!loading && <div className='literature-search'>
                  <div className='source-select'>
                    <p className='button-text-initial'>Upload supporting literature:</p>
                    <div className='source-button-container'>

                      {literatureList.length === 0 ?
                        <>
                          <button key='source-button' onClick={() => handleLiteratureModalOpen()} className="source-button" type="submit">
                            <p className='button-text-initial'>Upload Files</p>
                          </button>
                        </> :
                        <>
                          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                          {literatureList.map((file) =>
                            <div className='multiple-file-upload'>
                              <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                            </div>
                          )}
                        </>}
                    </div>
                  </div>
                  <div className='literature-search'>
                    <p style={{ textAlign: 'center' }} className='button-text-initial'>Or search the web</p>
                    <p className='button-text-initial'>Published literature keywords:</p>

                    <Autocomplete
                      multiple
                      value={litTags}
                      onChange={(event, newLitTags) => {
                        setLitTags(newLitTags);
                      }}
                      freeSolo
                      options={[]}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{minWidth: '600px'}}
                          variant="outlined"
                          value={litInputValue}
                          onChange={handleLitInputChange}
                          onKeyDown={handleLitInputKeyDown}
                        />
                      )}
                    />
                  </div>
                  <div className='upload-green' style={{marginTop:10}} onClick={async () => {
                  if (protocolList.length > 0) {
                  await uploadMultipleFilesUpdated(setLoading, protocolList, protocolContentList, setError, 'protocols');
                  }
                  if (protocolTags.length > 0) {
                  await searchProtocols(setLoading, protocolTags, setError);
                  }
                  handleContinue(1)

                  }}>
                    <p style={{fontSize:'20px'}} className='button-text-white'>Next</p>
                  </div>
                </div>}
              </div>
              </Slide>
              <Slide direction="up" in={slide === 6} mountOnEnter unmountOnExit>
                <div className='initial-upload-container'>
                  <div className='literature-search'>
                    <div className='source-select'>
                      <p className='button-text-initial'>Upload previous protocols:</p>
                      <div className='source-button-container'>

                        {protocolList.length === 0 ?
                          <>
                            <button key='source-button' onClick={() => handleProtocolModalOpen()} className="source-button" type="submit">
                              <p className='button-text-initial'>Upload Files</p>
                            </button>
                          </> :
                          <>
                            <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                            {protocolList.map((file) =>
                              <div className='multiple-file-upload'>
                                <p key={file.name} style={{ padding: 0 }}>{file.name}</p>
                              </div>
                            )}
                          </>}
                      </div>
                      <div className='literature-search'>
                        <p style={{ textAlign: 'center' }} className='button-text-initial'>Or search the web</p>
                        <p className='button-text-initial'>Published protocol keywords:</p>


                        <Autocomplete
                          multiple
                          value={protocolTags}
                          onChange={(event, newLitTags) => {
                            setProtocolTags(newLitTags);
                          }}
                          freeSolo
                          options={[]}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              value={protocolInputValue}
                              onChange={handleProtocolInputChange}
                              onKeyDown={handleProtocolInputKeyDown}
                              style={{ minWidth: '600px' }}
                            />
                          )}
                        />
                      </div>
                      <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                        if (protocolList.length > 0) {
                          await uploadMultipleFilesUpdated(setLoading, protocolList, protocolContentList, setError, 'protocols');
                        }
                        if (protocolTags.length > 0) {
                          searchProtocols(setLoading, protocolTags, setError);
                        }
                        handleContinue(1)

                      }}>
                        <p style={{fontSize:'20px'}} className='button-text-white'>Next</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide direction="up" in={slide === 7} mountOnEnter unmountOnExit>
                {<div className='initial-upload-container' style={!loading ? {margin:'auto', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}: {display:'none'}}>
                  <div className='upload-green' style={{ marginTop: 10 }} onClick={async () => {
                    if (demo) {
                      setLoading(true)
                      await delay(2000)
                      setLoading(false)
                      setSourceFile(sourceData)
                      const flatList = ['cb4dbc01-27eb-4717-baf9-c25cbb940524-hoffmansynopsisfullgenpdfprotocoltemplatedocx.docx'];
                      setProtocolFile('https://3f693757d182022c.blob.core.windows.net/output/hoffmansynopsisfullgenpdf-protocol_final_02-08-24.docx')
                      await createOutputVectorStore(flatList, randomId, setLoading);
                      


                    } else {
                      setLoading(true)
                      console.log('multipleFileList2', multipleFileList2)
                      const flatList = multipleFileList2.map(item => item.name);
                      console.log('flatlist', flatList)
                      await updatedGenerateProtocol(setLoading, flatList, setError, setProtocolFile, setSourceFile, setFileKey, randomId)
                      
                      // await createOutputVectorStore(flatList, randomId, setLoading);
                      setLoading(false)

                    }
                  }}>
                    <p style={{fontSize:'20px'}} className='button-text-white'>Generate Protocol</p>
                  </div>
                </div>}
              </Slide>
            </>
          }

          {loading && <div className='loading-spinner-middle' style={loading ? { visibility: 'visible', display:'flex', flexDirection:'column', alignItems:'center', margin:'auto' } : { visibility: 'hidden' }}>
            <Grid
              height="80"
              width="80"
              color="#1DC989"
              secondaryColor="#"
              // wrapperClass="loading-spinner-right"
              visible={true}
            ></Grid>
            <p className='green-text'>Loading...</p>
          </div>}

{/* 
          {thirdLoading && <div className='loading-spinner-middle' style={thirdLoading ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <Grid
              height="80"
              width="80"
              color="#1DC989"
              secondaryColor="#"
              // wrapperClass="loading-spinner-right"
              visible={true}
            ></Grid>
            <p className='green-text'>Loading...</p>
          </div>} */}
          {/* <div className='document-box-container'> */}
          <div className={`document-box-container ${divClicked ? 'clicked' : ''}`} onClick={() => setDivClicked(true)}>
            <div style={{marginLeft:'20px'}}className='document-box'>
              {slide === 0 && !showDocumentSet && !protocolFile && previousDocs.length >= 1 && previousDocs.map((doc, index) =>
                <div className='document-container'>

                  {activeDoc === null &&
                    <DocumentBoxInternal doc={doc}
                      makeActive={makeActive}
                      index={index}
                      openInputFileModal={openInputFileModal} />
                  }
                  {activeDoc === index && <DocumentContainer protocolFile={doc.url} newDoc={false} inputDoc={doc.input_name} fileKey={fileKey} openInputFileModal={openInputFileModal} regenerateSubmit={regenerateSubmit2} selectedSubcategory={selectedSubcategory} setSelectedSubcategory={setSelectedSubcategory} sourceFile={doc.sources} textRef={textRef} hasLink={hasLink} />}
                </div>
              )}
              {slide === 0 && !protocolFile && showDocumentSet && previousDocs.length >= 1 &&
                <div className='document-container'>
                  <div className='document-box-internal' onClick={() => setShowDocumentSet(false)}>
                    <h4>IND for ABC-001</h4>
                    <hr className='separator' />
                    <div className='document-info'>
                      <div className='document-dates'>
                        <p className='document-date'>Created:</p>
                      </div>
                      <div className='document-dates' style={{ marginLeft: 10 }}>
                        <p className='document-date'>Jan. 12, 2024</p>
                      </div>
                    </div>
                    <div className='document-info'>
                      <div className='document-dates'>
                        <p className='document-date'>Last updated:</p>
                      </div>
                      <div className='document-dates' style={{ marginLeft: 10 }}>
                        <p className='document-date'>Feb. 3, 2024</p>
                      </div>
                    </div>
                    <div className='document-sources'>
                      <p className='document-date'>Sources:</p>
                      <div className='sources-box'>
                        {previousDocs.map((doc, index) => (
                          <div key={index}>
                            <p className='source'>{doc.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

              }
            </div>
          </div>
          {protocolFile &&
            <div className={`document-box-container clicked`} onClick={() => setDivClicked(true)}>
              <div className='document-box'>
                <div className='document-container'>
                  <DocumentContainer protocolFile={protocolFile} newDoc={true} fileKey={fileKey} openInputFileModal={openInputFileModal} regenerateSubmit={regenerateSubmit2} selectedSubcategory={selectedSubcategory} setSelectedSubcategory={setSelectedSubcategory} sourceFile={sourceFile} textRef={textRef} hasLink={hasLink} />
                </div>
              </div>
            </div>
          }
        </div>}
        {isActive(4) &&
          <ResearchComponent randomId={randomId}></ResearchComponent>}

        {isActive(6) &&
          <PlaygroundComponent randomId={randomId}></PlaygroundComponent>}
      </div>
      <Modal
        open={generateModalOpen}
        onClose={handleGenerateModalClose}>
        <div className='updated-modal-container'>
          {!protocolGeneration && <>
            <h4 style={{ fontSize: 20, fontFamily: "DM Sans" }}>Select a Template for Your Protocol:</h4>
            <button onClick={showProtocolForm} className='generate-button'>
              <p className='button-text'>ICH M11 Template</p>
            </button>
            <button onClick={showProtocolForm} className='generate-button'>
              <p className='button-text'>Custom Template</p>
            </button>
          </>}
          {protocolGeneration && <ProtocolForm handleFile={handleMultipleFiles} handleMultipleUpload={handleMultipleUpload} fileList={multipleFileList} filesContent={multipleFileContentList} setLoading={setLoading} setError={setError} resetAllFiles={resetAllFiles} questionList={questionList} setQuestionList={setQuestionList} loading={loading} protocolFile={protocolFile} setProtocolFile={setProtocolFile} setSourceFile={setSourceFile} closeModal={handleGenerateModalClose} setFileKey={setFileKey}></ProtocolForm>}

        </div>
      </Modal>
      <Modal
        open={warningModalOpen}
        onClose={handleWarningModalClose}>
        <div className='modal-container'>
          <h2>This will erase all files. Are you sure you want to continue?</h2>
          <button onClick={unselectFile} className='generate-button'>Yes</button>
          <button onClick={handleWarningModalClose} className='generate-button'>No</button>
        </div>
      </Modal>
      <Modal
        open={error}
        onClose={() => setError(false)}>
        <div className='modal-container'>
          <img height={50} width={50} style={{ marginRight: '10px' }} src={errorIcon}></img>
          <h2 className='blue-text'>Oops! Something went wrong. Please try again.</h2>
          <h2 className='blue-text' style={{ fontSize: 18 }}>Click anywhere to start the process over.</h2>
        </div>
      </Modal>
      <Modal
        open={openInputFile}
        onClose={() => setOpenInputFile(false)}>
        <div className='input-modal-container'>
          <div className='webviewContainer' style={{ height: '800px', width: '800px', marginLeft: '25px', visibility: 'visible' }}>
            <PDFWebViewComponent initialFile={inputFile} pageNumber={activePageNumber}></PDFWebViewComponent>
          </div>
        </div>
      </Modal>
      <Modal
        open={uploadModal}
        onClose={handleUploadModalClose}>
        <div className='updated-modal-container'>
          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload document(s) to create your protocol</h2>
          <MultipleFileUploader handleFiles={handleMultipleFiles2} />
        </div>
      </Modal>
      <Modal
        open={templateModal}
        onClose={handleTemplateModalClose}>
        <div className='updated-modal-container'>
          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload a .docx file with a template or sample  document</h2>
          <MultipleFileUploader handleFiles={handleTemplateFiles} />
        </div>
      </Modal>
      <Modal
        open={literatureModal}
        onClose={handleLiteratureModalClose}>
        <div className='updated-modal-container'>
          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload supporting literature</h2>
          <MultipleFileUploader handleFiles={handleLiteratureFiles} />
        </div>
      </Modal>
      <Modal
        open={protocolModal}
        onClose={handleProtocolModalClose}>
        <div className='updated-modal-container'>
          <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Upload previous protocol data</h2>
          <MultipleFileUploader handleFiles={handleProtocolFiles} />
        </div>
      </Modal>
    </LoadingOverlay>

  );
}

export default App;


