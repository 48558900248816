import React, { useState, useEffect } from 'react';
import { Button, Container, FormControl, TextField, FormControlLabel, h4, Radio, RadioGroup, Slide } from '@mui/material';
import CustomRadio from './RadioButton';
import { FileUploader } from './FileUploader';
import { MultipleFileUploader } from './MultipleFileUploader';
import { uploadAnswers } from '../utils/utils';
import WebViewComponent from './WebViewComponent';
import { generateProtocol } from '../utils/utils';
import { Grid } from 'react-loader-spinner';



const FormComponent = ({ handleFile, fileList, handleMultipleUpload, filesContent, setLoading, setError, resetAllFiles, questionList, setQuestionList, loading, protocolFile, setProtocolFile, setSourceFile, closeModal, setFileKey }) => {
  const [step, setStep] = useState(-1);
  const [formData, setFormData] = useState({});
  const [testing, setTesting] = useState(false)
  // setLoading(false);

  let result;
  useEffect(() => {
    // Update myObject when myList changes
    const newObject = {};
    questionList.forEach((item, ind) => {
      newObject[ind] = null; // You can assign a value here if needed
    });
    setFormData(newObject);
  }, [questionList]);

  const zipData = () => {
    const questionsAnswers = questionList.map((question, index) => {
      return {
        question,
        answer: {
          response: formData[index], // Assuming formData contains the responses
          info_available: formData[index] !== null
        },
        sources: [
          {
            "page_content": formData[index],
            "metadata": {
                "source": "",
                "page": 1
            }
        }
        ]
      };
    });

    result = {
      input_path: fileList[0].name,
      questions_answers: questionsAnswers
    };

    // Handle the zipped data (e.g., console log or API call)
    console.log(result);
  };



  const handleFieldChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleContinue = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleReturn = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleClick = async () => {
    setLoading(true);
    zipData();
    try {
      await uploadAnswers(setLoading, result, setError);
      await generateProtocol(setLoading, fileList[0].name, setError, setProtocolFile, setSourceFile, setFileKey);
      setLoading(false);
      handleContinue();
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
      
    }
  };

  return (
    <div>
      {loading && <div className='loading-spinner-right' style={loading ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Grid
          height="80"
          width="80"
          color="#1DC989"
          secondaryColor="#"
          // wrapperClass="loading-spinner-right"
          visible={true}
        ></Grid>
        <p className='green-text'>Loading...</p>
      </div>}
      {!loading && <Container>
        {/* {formData && testing && questionList.map((item, ind) => (
          <Slide direction="down" in={step === ind} mountOnEnter unmountOnExit>
            {console.log('generate protocol button hide')}
            <div className='form-box'>
              <FormControl component="fieldset">
                <h2>Question {ind + 1}/{questionList.length}:</h2>
                <h4 className='question-text' component="legend">{item}</h4>
                <TextField
                  multiline={true}
                  rows={5}
                  style={{ minWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
                  label={"Question " + (ind + 1)}
                  value={formData[ind]}
                  onChange={(e) => handleFieldChange(ind, e.target.value)}
                  margin="normal"
                />
              </FormControl>
              <button className='continue-button' onClick={handleContinue}>
                Continue
              </button>
              <button className='continue-button' onClick={handleContinue}>
                Skip
              </button>
              <button className='continue-button' onClick={handleReturn}>
                Return
              </button>
              <div>
                <p style={{textAlign:'center'}} className='blue-text'>Note: skipping questions may result in sections being incomplete</p>
              </div>
            </div>
          </Slide>

        ))} */}

        {/* Add more steps as needed */}
        {step >= 0 && !protocolFile && (
          <div className='form-box'>
            <h2>All Information Gathered</h2>
            <button className='generate-button' onClick={handleClick}>
              <p className='button-text'>Generate Protocol</p></button>
          </div>
          // <Slide in={step === 0} mountOnEnter unmountOnExit>
          //   {console.log('generate protocol button show', step)}
          //   <div className='form-box'>
          //     <h2>All Information Gathered</h2>
          //     <button className='generate-button' onClick={handleClick}>
          //       <p className='button-text'>Generate Protocol</p></button>
          //   </div>
          // </Slide>
        )}

        {/* Display a summary or success message on the last step */}
        {step < 0 && (
          <Slide direction="up" in={step === -1} mountOnEnter unmountOnExit>
            <div className='form-box'>
              {fileList.length < 1 &&
                <>
                  <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20,  color: '#1F477D'}}>Upload document(s) to create your protocol</h2>
                  <MultipleFileUploader handleFiles={handleFile} />
                </>}

              {fileList.length >= 1 && (<>
                <h2 style={{ fontFamily: "DM Sans", fontSize: 20, fontWeight: 200, marginBottom: 20, color: '#1F477D' }}>Files Selected:</h2>
                {fileList.map((file) =>
                  <div className='multiple-file-upload'>
                    <p key={file.name} style={{padding: 0}}>{file.name}</p>
                  </div>
                )}
                <div style={{ justifyContent: 'center', alignItems: 'center', borderWidth: "1" }}>
                  <button className='upload-green-initial' onClick={() => {
                    handleMultipleUpload(setLoading, fileList, filesContent, setError, setQuestionList);
                    handleContinue();
                  }}>
                    <p className='button-text'>Upload</p></button>

                  <button className='upload-blue-initial' onClick={() => resetAllFiles()}>
                    <p className='button-text'>Clear</p></button>
                </div>


              </>)}
              {/* Display other fields as needed */}
            </div>
          </Slide>
        )}

        {protocolFile && (
          <Slide direction="down" in={step === questionList.length + 1} mountOnEnter unmountOnExit>
            <div className='form-box'>
              <h2>Protocol Generated</h2>
              <button className='generate-button' onClick={() => closeModal()}>
                <p className='button-text'>View Protocol</p></button>
              {/* <div className='webviewContainer' style={protocolFile ?  {height:'600px', width:'800px', visibility:'visible'} : {height:'0', width:'0', visibility:'hidden'} }>
              <WebViewComponent initialFile={protocolFile}></WebViewComponent>
            </div> */}
              {/* Display other fields as needed */}
            </div>
          </Slide>
        )}


      </Container>}
    </div>
  );
};

export default FormComponent;
