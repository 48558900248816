import React, { useState } from 'react';
import close from '../icons/Close_round.svg';
import { ChatContainer, MessageList, MessageInput, Message, Avatar, MainContainer, TypingIndicator} from '@chatscope/chat-ui-kit-react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { chatWithBot } from '../utils/utils';
import chatanswers1 from '..//staticFiles/chatanswers1.json';
import chatanswers2 from '..//staticFiles/chatanswers2.json';
import { chatWithDoc } from '../utils/utils';

function ChatSection({handleClose, openInputModal, inputDoc, protocolFile}) {
  const [messages, setMessages] = useState([{ answer: "Hello! Ask me anything about this document", sources:[],  direction: 'incoming' }]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [first, setFirst] = useState(true);

  const handleSend = async () => {
    // Add the user's message to the message list
    setMessages([...messages, { answer: inputValue, direction: 'outgoing', sources: [''] }]);
  
    setIsTyping(true); // Start typing
  
    // chatWithBot(inputValue, protocolFile).then(response => {
    //   console.log('response', response)
    //   // Process the API response

    //   if (first) {
    //         const data = chatanswers1; // Replace this with the actual response data
    //         console.log('data', data)
        
    //         if (data) {
    //             const answer = data.answer;
    //             const sources = data.sources.slice(0, 1).map(source => ({
    //             text: `${source.metadata.source} p2`
    //             }));
    //             console.log('sources', sources)
    //             // Add the API's response to the message list
    //             setMessages(prevMessages => [
    //             ...prevMessages,
    //             {
    //                 answer: answer,
    //                 sources: sources,
    //                 direction: 'incoming'
    //             }
    //             ]);
    //         } else {
    //             console.error('Invalid response:', response);
    //         }
        
    //         setInputValue('');
    //         setIsTyping(false); // Stop typing
    //         setFirst(false)
    //     } else { 
    //         const data = chatanswers2; // Replace this with the actual response data
    //         console.log('data', data)
        
    //         if (data) {
    //             const answer = data.answer;
                
    //             const sources = data.sources.slice(0, 1).map(source => ({
    //             text: "https://seer.cancer.gov/statfacts/html/breast-subtypes.html"})
    //             );
    //             console.log('sources', sources)
    //             // Add the API's response to the message list
    //             setMessages(prevMessages => [
    //             ...prevMessages,
    //             {
    //                 answer: answer,
    //                 sources: sources,
    //                 direction: 'incoming'
    //             }
    //             ]);
    //         } else {
    //             console.error('Invalid response:', response);
    //         }
        
    //         setInputValue('');
    //         setIsTyping(false); // Stop typing
    //     }
    // });

    chatWithDoc(inputValue ).then(response => {
        console.log('response', response)
        // Process the API response

        const data = response;
        console.log('data')

        if (data) {
            const answer = data
            setMessages(prevMessages => [
                ...prevMessages,
                {
                    answer: answer,
                    sources: '',
                    direction: 'incoming'
                }
                ]);
            } else {
                console.error('Invalid response:', response);
            }
            setInputValue("");
            setIsTyping(false); // Stop typing
            
  
        // if (first) {
        //       const data = chatanswers1; // Replace this with the actual response data
        //       console.log('data', data)
          
        //       if (data) {
        //           const answer = data.answer;
        //           const sources = data.sources.slice(0, 1).map(source => ({
        //           text: `${source.metadata.source} p2`
        //           }));
        //           console.log('sources', sources)
        //           // Add the API's response to the message list
        //           setMessages(prevMessages => [
        //           ...prevMessages,
        //           {
        //               answer: answer,
        //               sources: sources,
        //               direction: 'incoming'
        //           }
        //           ]);
        //       } else {
        //           console.error('Invalid response:', response);
        //       }
          
        //       setInputValue('');
        //       setIsTyping(false); // Stop typing
        //       setFirst(false)
        //   } else { 
        //       const data = chatanswers2; // Replace this with the actual response data
        //       console.log('data', data)
          
        //       if (data) {
        //           const answer = data.answer;
                  
        //           const sources = data.sources.slice(0, 1).map(source => ({
        //           text: "https://seer.cancer.gov/statfacts/html/breast-subtypes.html"})
        //           );
        //           console.log('sources', sources)
        //           // Add the API's response to the message list
        //           setMessages(prevMessages => [
        //           ...prevMessages,
        //           {
        //               answer: answer,
        //               sources: sources,
        //               direction: 'incoming'
        //           }
        //           ]);
        //       } else {
        //           console.error('Invalid response:', response);
        //       }
          
        //       setInputValue('');
        //       setIsTyping(false); // Stop typing
        //   }
      });
  };
  

  return (
    <div className='right-panel-content'>
      <div className='right-panel-header'>
        <h2 className='blue-text'>Chat</h2>
        {/* <img className='close-button' src={close} onClick={() => handleClose()}></img> */}
      </div>
      <div className='chat-box'>
        <MainContainer>
            <ChatContainer>
        
            <MessageList>
                {messages.map((message, index) => (
                <div className={message.direction==='incoming' ? 'message-box-dark' : 'message-box-light'}>
                    <Message
                        key={index}
                        model={{
                            message: message.direction === 'incoming'
                            ? message.answer && 
                            `<p style="font-size: 14px; color: black">${message.answer}</p>` +
                            (message.sources && message.sources.length > 0 
                                ? `<p style="font-size: 12px; color:grey">Sources:</p>` +
                                message.sources.map((source, index) => 
                                    `<p key=${index} style="font-size: 12px; color:black; text-overflow: ellipsis; background-color: #EBF0FF;
                                    max-width: 200px;
                                    overflow:hidden; 
                                    border-radius: 2px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    font-style: italic;">${source.text}</p>`
                                  ).join('')
                                : '')
                            : `<p style="font-size: 16px; color: white">${message.answer}</p><p style="font-size: 10px; color:white">${message.sources}</p>`,
                            sender: 'Bot',
                            direction: message.direction,
                        }}
                    />
                </div>
                ))}
                {isTyping && <TypingIndicator />}
            </MessageList>
            <MessageInput
                value={inputValue}
                onChange={setInputValue}
                onSend={handleSend}
                attachButton={false}
                placeholder='Type your message here...'
            />
            </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
}

export default ChatSection;