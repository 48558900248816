import React, {useState, useEffect} from 'react';
import WebViewComponent from './WebViewComponent';
import ChatSection from './ChatSection';
import RegenerateSection from './RegenerateSection';
import SourcePanel from './SourcePanel';
import InconsistencyComponent from './InconsistencyPanel';
import refresh from '../icons/material-symbols-light_refresh-rounded.svg';
import chat from '../icons/lets-icons_chat-light.svg';
import sourceIcon from '../icons/carbon_connect.svg'
import { createVectorStore, saveProtocol } from '../utils/utils';
const DocumentContainer = ({ protocolFile, newDoc, inputDoc, fileKey, openInputFileModal, regenerateSubmit, selectedSubcategory, setSelectedSubcategory, sourceFile, textRef, hasLink }) => {
    const [rightPanel, setRightPanel] = useState(true)
    const [regenOpen, setRegenOpen] = useState(false)
    const [sourceOpen, setSourceOpen] = useState(false)
    const [chatOpen, setChatOpen] = useState(true)
    const [inconsistencyOpen, setInconsistencyOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false)
    const [secondLoading, setSecondLoading] = useState(false)
    const [error, setError] = useState(false)

    // const regenerateSubmit = async () => {
    //     if (newDoc) {
    //       console.log('textRef', textRef.current.value)
    //       console.log('protocolName',protocolFile)
    //       await regenerateSection(setSecondLoading, protocolFile, selectedSubcategory, textRef.current.value, setError, setProtocolFile, setSourceFile, setFileKey)
    //       await saveProtocol(protocolName)
    //     } else {
    //       const fileName = protocolFile.split("/").pop();
    //       console.log('protocol file name:', fileName);
    //       await regenerateSection(setSecondLoading, fileName, selectedSubcategory, textRef.current.value, setError, setProtocolFile, setSourceFile, setFileKey)
    //       await saveProtocol(fileName)
    //     }
    
    //     handleFileContentChange();
    
    //     textRef.current.value = ''
    //   }

    useEffect(() => {
        const initChat = async () => {
            if (newDoc) {
                await createVectorStore(protocolFile,setSecondLoading, setError)
            } else {
                const fileName = protocolFile.split("/").pop();
                console.log('protocol file name:', fileName);
                await createVectorStore(fileName, setSecondLoading, setError)
            }
        }
        initChat();
    }, [])


    const openRegen = () => {
        setRegenOpen(true)
        setSourceOpen(false)
        setChatOpen(false);
        setInconsistencyOpen(false);
      }
    
      const openSource = () => {
        setSourceOpen(true)
        setRegenOpen(false)
        setChatOpen(false)
        setInconsistencyOpen(false);
      }
    
      const openChat =  async () => {
        setChatOpen(true)
        setRegenOpen(false)
        setSourceOpen(false)
        setInconsistencyOpen(false);
        console.log('initializing chat!!!')
        await initChat();
    
      }

    const openInconsistency = () => {
        setInconsistencyOpen(true)
        setRegenOpen(false)
        setSourceOpen(false)
        setChatOpen(false);
    }

    const closeTab = () => {
        setRegenOpen(false)
        setSourceOpen(false)
        setChatOpen(false)
        setInconsistencyOpen(false);
      }

    const initChat = async () => {
    if (newDoc) {
        
        // await createVectorStore(protocolFile,setSecondLoading, setError)
    } else {
        const fileName = protocolFile.split("/").pop();
        console.log('protocol file name:', fileName);
        // await createVectorStore(fileName, setSecondLoading, setError)
    }
    }
    return (
                <>
                    <div className='webviewContainer' style={protocolFile ? { height: "100vh", width: '70vw', visibility: 'visible' } : { height: '0', width: '0', visibility: 'hidden' }}>
                        <WebViewComponent hasLink={hasLink} fileKey={fileKey} initialFile={protocolFile}></WebViewComponent>
                    </div>
                    {rightPanel && <div className='right-panel' style={protocolFile ? { visibility: 'visible' } : { visibility: 'hidden', width: '0', height: '0' }}>
                        <div style={{display: "flex", justifyContent: "flex-start", flexDirection:'column'}}>
                        <div className='tab-button-container'>
                            <div className='side-button green' onClick={() => openChat()}>
                            <img width={20} height={20} style={{marginRight:'10px'}} src={chat}/>
                            <p className='button-text-white'>Chat</p>
                            </div>
                            <div className='side-button blue' onClick={() => openSource()}>
                            <img width={20} height={20} style={{marginRight:'10px'}} src={sourceIcon}/>
                            <p className='button-text-white'>Sources</p>
                            </div>
                            <div className='side-button grey' onClick={() => openRegen()}>
                            <img width={20} height={20} style={{marginRight:'10px'}} src={refresh}/>
                            <p className='blue-text'>Redraft</p>
                            </div>
                        </div>
                        <div className='save-button' onClick={() => openInconsistency()}>
                            <p style={{fontSize:'18px'}} className='blue-text'>Check Consistency</p>
                        </div>
                        {/* <div className='save-button' onClick={() => saveProtocol(protocolFile)}>
                            <p style={{fontSize:'18px'}} className='blue-text'>Save</p>
                        </div> */}
                        {chatOpen && <ChatSection handleClose={closeTab} openInputModal={openInputFileModal} protocolFile={protocolFile}></ChatSection>}
                        {regenOpen && <RegenerateSection textRef={textRef} handleSubmit={regenerateSubmit} handleClose={closeTab} selectedSubcategory={selectedSubcategory} setSelectedSubcategory={setSelectedSubcategory} />}
                        {sourceOpen && <SourcePanel handleClose={closeTab} data={sourceFile} openInputModal={openInputFileModal} inputDoc={'https://3f693757d182022c.blob.core.windows.net/input/hoffman-synopsis-full-gen.pdf'} />}
                        {inconsistencyOpen && <InconsistencyComponent handleClose={closeTab} data={sourceFile} openInputModal={openInputFileModal} inputDoc={'https://3f693757d182022c.blob.core.windows.net/input/hoffman-synopsis-full-gen.pdf'} />}
                        </div>
                    </div>}
                </>
    );
};

export default DocumentContainer;