import { useRef } from "react";
import "../App.css";
import upload from "../icons/heroicons-outline_upload.svg";

export const MultipleFileUploader = ({ handleFiles }) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    event.preventDefault()
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected files
  const handleChange = (event) => {
    const filesUploaded = event.target.files;
    const fileContents = [];


    // Iterate through the selected files
    for (let i = 0; i < filesUploaded.length; i++) {
      const currentFile = filesUploaded[i];

      if (currentFile.size > 10 * 1024 * 1024) {
        alert(`File ${currentFile.name} size must be less than 10MB.`);
        continue; // Skip this file and move to the next one
      }

      // Use FileReader to read the content of each PDF file
      const reader = new FileReader();
      reader.onload = function (e) {
        fileContents.push(e.target.result);
        console.log('e.target.result', e.target.result)

        // Call the function to handle the user-selected file
        
      };
      reader.readAsArrayBuffer(currentFile);
     
    }
    handleFiles(filesUploaded, fileContents);

    event.target.value = ""; // Clear the input field after handling files
  };

  return (
    <>
      {/* <button key="select-button" className="generate-button" onClick={handleClick}>
        <p className='button-text-white'>Select Files</p>
      </button> */}

      <div className='select-file-box' onClick={handleClick}>
        <img style={{alignSelf:'center'}}src={upload} height={80} width={80}></img>
        <p className='blue-text'style={{textAlign:'center', marginBottom: 0, fontSize: 18, fontWeight: 700}}>Drag and drop files here</p>
        <p className='blue-text' style={{textAlign:'center', marginTop: 2, textDecoration: 'underline'}}>or click to browse</p>      </div>
      <input
        type="file"
        accept="pdf,docx"
        multiple  // Enable multiple file selection
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </>
  );
};
