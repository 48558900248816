import {useEffect, useState, useRef} from 'react';
import WebViewer from '@pdftron/webviewer'
import { saveFile } from '../utils/utils';
import save from '../icons/Save.svg';

function WebViewComponent({hasLink, initialFile, fileKey}) {
  const viewer = useRef(null);
  const [instance, setInstance] = useState(null);


  

  useEffect(() => {
    WebViewer(
      {
        path: './public',
        licenseKey: process.env.REACT_APP_APRYSE_KEY,
        initialDoc: null,
        enableOfficeEditing:true,
        // extension: 'docx',
        // loadAsPDF: true,
      },
      viewer.current,
    ).then((i) => {
        setInstance(i);
        // const { docViewer } = i
        const { Actions, documentViewer, Annotations, annotationManager, Search, Tools } = i.Core;

        documentViewer.setSearchHighlightColors({
          // setSearchHighlightColors accepts both Annotations.Color objects or 'rgba' strings
          searchResult: new Annotations.Color(0, 0, 255, 0.5),
          activeSearchResult: 'rgba(0, 255, 0, 0.5)'
        });
        const showSelectedText = () => {
          const page = documentViewer.getCurrentPage();
          console.log('page',page)
          const text = documentViewer.getSelectedText(page);
      
          if (text) {
              console.log('text', text);
          }
        }

        documentViewer.addEventListener('keyDown', function (e) {
          if (e.keyCode == 67 && (e.ctrlKey || e.metaKey)) {
            showSelectedText();
            console.log('Ctrl+C pressed');
          }
        });

        


        const tool = documentViewer.getTool(Tools.ToolNames.TEXT_SELECT);
        tool.addEventListener('textSelected', (startQuad, allQuads) => {
          let selectedText = '';
          Object.keys(allQuads).forEach(pageNum => {
            const text = documentViewer.getSelectedText(pageNum);
            selectedText += text;
          });
          console.log('selectedText from tool:', selectedText)
          // the startQuad and allQuads will have the X and Y values you want
        });

        documentViewer.addEventListener('documentLoaded', () => {
          console.log('doc Loaded HERE')
          
          
          documentViewer.addEventListener('textSelected', (quads, selectedText, pageNumber) => {
            // quads will be an array of 'Quad' objects
            // text is the selected text as a string
            if (selectedText.length > 0) {
              console.log('page number', selectedText, pageNumber);
            }
            
          });

          const newLink = new Annotations.Link();
          newLink.PageNumber = 7;
          newLink.X = 100;
          newLink.Y = 95;
          newLink.Width = 200;
          newLink.Height = 100;

          /** * Optional: Add styling to link to indicate to user the annotation has a * link associated with it */
          // newLink.StrokeColor = new Annotations.Color(0, 165, 228);
          newLink.StrokeStyle = 'underline';
          newLink.StrokeThickness = 2;

          const pageToLinkTo = 28;

          newLink.addAction(
            'U',
            new Actions.GoTo({
              dest: new Actions.GoTo.Dest({
                page: pageToLinkTo,
              }),
            }),
          );
          console.log('hasLink', hasLink)

          if (hasLink) {
            annotationManager.addAnnotation(newLink);
            // annotationManager.groupAnnotations(originalAnnotation, [newLink]);
            // Re-render annotations (requires major redraw)
            annotationManager.drawAnnotationsFromList(newLink.PageNumber);
          }

          i.UI.setHeaderItems(header => {
            header.push({
                type: 'actionButton',
                img: save,
                onClick: async () => {
                  const doc = documentViewer.getDocument();
                  console.log('doc', initialFile)
                  // const xfdfString = await annotationManager.exportAnnotations();
                  const data = await doc.getFileData({
                    // saves the document with annotations in it
                    // xfdfString
                  });
                  const arr = new Uint8Array(data);
                  const blob = new Blob([arr]);
                  console.log('blob', blob)
                  const newName = initialFile.split('/').pop();
                  await saveFile(newName, blob);
  
                  // Add code for handling Blob here
                }
            });
          });       
          
        });  
      });
  }, []);

  useEffect(() => {
    if (instance) {


      console.log('Loading new document:', initialFile);
      instance.UI.loadDocument(initialFile, {extension:'docx', enableOfficeEditing:true})
    }
 }, [initialFile,instance, fileKey])

  return (
    <div className="MyComponent">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
};


export default WebViewComponent;

